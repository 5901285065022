import {Component, OnDestroy, OnInit} from "@angular/core";
import {User} from "../user.model";
// import {AdminService} from "../user.service";
import {Observable, Subscription} from "rxjs";
import {UserService} from "../user.service";
import {DialogConfirmation} from "../../../shared/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {AdminService} from "../../admins/admin.service";
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy{
  users: User[] = [];
  isLoading = false
  adminIsAuthenticated = false
  private userSub: Subscription;

  totalProducts = 0;
  productsPerPage = 10;
  pageSizeOptions = [1, 2, 5, 10, 20]
  currentPage = 1;

  hasFound = false;


  searchBarListenerSub: Subscription;
  clientsList = []
  clientsListNames = []
  clientSelectedId: string;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  searchedClient: User;


  constructor(public  userService: UserService, public dialog: MatDialog,
              private adminService: AdminService) {  }

  ngOnInit() {
    this.adminIsAuthenticated = this.adminService.getIsLogged();

    this.isLoading = true;

    this.userService.getListOfClientsNamesToSearch();
    this.searchBarListenerSub = this.userService.getClientsListListener()
      .subscribe((respData) => {
        this.clientsList = respData.clientsList

        for(let i = 0; i < this.clientsList.length; i++){
          this.clientsListNames.push(this.clientsList[i]['names'])
        }
      })

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.userService.getUsers(this.productsPerPage,this.currentPage);
    this.userSub = this.userService.getUserUpdateListener()
      .subscribe((users: User[]) =>{
        this.isLoading = false;
        this.users = users;
    })


  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.clientsListNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  searchClient(client){

    if(this.clientsListNames.includes(client[0])){
      const index = this.clientsListNames.indexOf(client[0])

      this.clientSelectedId = this.clientsList[index]['_id'];

      this.userService.getUser(this.clientSelectedId).subscribe(userData => {
        this.searchedClient = {
          _id: userData._id,
          sex: userData.sex,
          birthday: userData.birthday,
          ruc: userData.ruc,
          password: userData.password,
          names: userData.names,
          lastNames: userData.lastNames,
          province: userData.province,
          city: userData.city,
          address: userData.address,
          phone1: userData.phone1,
          phone2: userData.phone2,
          email: userData.email,
          category: userData.category,
          discount: userData.discount,
        }
        // console.log(this.searchedClient)

        this.hasFound = true;

        this.myControl.setValue('');

        // Prevent for runnig twice
        // for(let i = 0; i < 10000; i++){}
      })

    }
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.searchBarListenerSub.unsubscribe();
  }

  onDelete(userId){
    this.userService.deleteUser(userId);
  }

  openDialog(userId) {
    const dialogRef = this.dialog.open(DialogConfirmation);

    dialogRef.afterClosed().subscribe(result => {
      if(result === true){
        this.onDelete(userId)
      }

    });
  }

  onChangedPage(pageData: PageEvent){
    // console.log(pageData)
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    // console.log(this.currentPage)

    this.productsPerPage = pageData.pageSize;
    this.userService.getUsers(this.productsPerPage,this.currentPage)

  }


}
