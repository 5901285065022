import {Component, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";

import {PageEvent} from "@angular/material/paginator";
import {CategoryService} from "../category.service";

import {DomSanitizer} from "@angular/platform-browser";
import {DialogConfirmation} from "../../shared/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {Category} from "../category.model";
import {AdminService} from "../../users/admins/admin.service";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-product-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit, OnDestroy {
  categories: Category[] = [];
  mainImg: string[] = [];
  isLoading = false

  productsList = []
  productsListNames = []
  productSelectedId: string;
  myControl = new FormControl();

  searchedProduct: Category;


  private categoriesSub: Subscription;
  adminIsAuthenticated = false;
  private authStatusSubs: Subscription;

  constructor(private authService: AdminService, public categoriesService: CategoryService, private  _sanitizer: DomSanitizer,
              public dialog: MatDialog,) {
  }


  ngOnInit() {
    this.adminIsAuthenticated = this.authService.getIsLogged();
    this.authStatusSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.adminIsAuthenticated = isAuthenticated
      })
    this.isLoading = true;
    this.categoriesService.getCategories();
    this.categoriesSub = this.categoriesService.getCategoryUpdateListener()
      .subscribe((categoriesData: { categories: Category[] }) => {
        this.isLoading = false;
        this.categories = categoriesData.categories;
      });
  }

  ngOnDestroy() {
    this.categoriesSub.unsubscribe();
    this.authStatusSubs.unsubscribe();
  }

  onDelete(productId) {
    this.isLoading = true;
    this.categoriesService.deleteCategory(productId).subscribe(() => {
      this.categoriesService.getCategories();
    });
  }

  openDialog(productId) {
    const dialogRef = this.dialog.open(DialogConfirmation);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.onDelete(productId)
      }
    });
  }
}
