<div>
  <app-header></app-header>
  <main>
    <mat-sidenav-container >
      <!-- Sidenav -->
      <mat-sidenav #sidenav mode="side" opened *ngIf="adminIsAuthenticated">
        <mat-nav-list>
          <a mat-list-item routerLinkActive="mat-accent" routerLink="dashboard"><mat-icon>dashboard</mat-icon>Principal</a>

          <a mat-list-item routerLink="categorias/listar"><mat-icon>apps</mat-icon>Categorías</a>

          <a mat-list-item routerLink="productos/listar"><mat-icon>store</mat-icon>Productos</a>
          <a mat-list-item routerLink="usuarios/listar"><mat-icon>people_alt</mat-icon>Clientes</a>
          <a mat-list-item routerLink="administradores/listar"><mat-icon>assignment_ind</mat-icon>Administradores</a>
          <a mat-list-item><mat-icon>settings</mat-icon>Configuraciones</a>
        </mat-nav-list>
      </mat-sidenav>

      <!-- Main content -->
      <mat-sidenav-content >
        <!-- Angular material cards -->
        <div class="productCards">
          <router-outlet></router-outlet>
        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>

  </main>

</div>
