import {Component, OnDestroy, OnInit} from "@angular/core";
import {Admin} from "../admin.model";
// import {AdminService} from "../user.service";
import {Subscription} from "rxjs";
import {AdminService} from "../admin.service";
import {DialogConfirmation} from "../../../shared/dialog.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-user-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit, OnDestroy{
  users: Admin[] = [];
  isLoading = false
  private userSub: Subscription;
  adminIsAuthenticated = false;
  constructor(public  userService: AdminService, public dialog: MatDialog) {  }

  ngOnInit() {
    this.adminIsAuthenticated = this.userService.getIsLogged();

    this.isLoading = true;


    this.userService.getUsers();
    this.userSub = this.userService.getUserUpdateListener()
      .subscribe((users: Admin[]) =>{
        this.isLoading = false;

        this.users = users;
    })
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  onDelete(userId){
    this.userService.deleteUser(userId);
  }

  openDialog(userId) {
    const dialogRef = this.dialog.open(DialogConfirmation);

    dialogRef.afterClosed().subscribe(result => {
      if(result === true){
        this.onDelete(userId)
      }

    });
  }
}
