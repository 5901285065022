<script src="../product-list/product-list.component.ts"></script>
<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">
    <section class="mat-typography title-group">
      <span class="title-group"
        ><a
          mat-button
          routerLink="/productos/listar"
          routerLinkActive="mat-accent"
          ><h2>Productos</h2></a
        ></span
      >
      <span class="spacer"></span>
      <ul class="title-group">
        <li>
          <a
            mat-button
            routerLink="/productos/crear"
            routerLinkActive="mat-accent"
            >Crear</a
          >
        </li>
      </ul>
      <mat-divider></mat-divider>
    </section>

    <!--    {{formGroup.value | json}}-->

    <mat-card>
      <mat-spinner color="accent" *ngIf="isLoading"></mat-spinner>

      <form
        [formGroup]="formGroup"
        (ngSubmit)="onSaveProduct(formGroup, stepper, formDirective)"
        #formDirective="ngForm"
        (keydown.enter)="$event.preventDefault()"
        *ngIf="!isLoading"
      >
        <mat-horizontal-stepper #stepper formArrayName="formArray" linear>
          <mat-step
            formGroupName="0"
            [stepControl]="formArray.get([0])"
            errorMessage="Campos incompletos."
          >
            <!--        Form for setting the basic info of a product: ref, name, producer, description-->
            <ng-template matStepLabel>Información básica</ng-template>

            <!--                {{ formGroup.value | json }}-->

            <mat-card class="sub-field">
              <div *ngIf="mode === 'edit'; else elseCharBlock">
                <div>
                  <mat-slide-toggle
                    [ngModel]="isAvailable"
                    [checked]="isAvailable"
                    formControlName="available"
                    >Producto disponible
                  </mat-slide-toggle>
                </div>
                <div>
                  <mat-slide-toggle
                    [ngModel]="mainProduct"
                    formControlName="mainProduct"
                    >Producto estrella
                  </mat-slide-toggle>
                </div>
              </div>
              <ng-template #elseCharBlock>
                <div>
                  <mat-slide-toggle
                    [ngModel]="isAvailable"
                    formControlName="available"
                    >Producto disponible
                  </mat-slide-toggle>
                </div>
                <div>
                  <mat-slide-toggle
                    [ngModel]="mainProduct"
                    formControlName="mainProduct"
                    >Producto estrella
                  </mat-slide-toggle>
                </div>
              </ng-template>

              <mat-form-field appearance="fill">
                <mat-label>Categoría</mat-label>
                <mat-select
                  required
                  formControlName="category"
                  [value]="product?.category"
                >
                  <mat-option
                    *ngFor="let p of productsCategories"
                    [value]="p._id"
                  >
                    {{ p.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card>

            <mat-card class="sub-field">
              <mat-form-field>
                <mat-label>Producto</mat-label>
                <input
                  matInput
                  [ngModel]="product?.name"
                  formControlName="nameCtrl"
                />
              </mat-form-field>
            </mat-card>

            <mat-card class="sub-field">
              <mat-form-field>
                <mat-label>Descripción</mat-label>
                <textarea
                  matInput
                  rows="7"
                  name="description"
                  [ngModel]="product?.description"
                  formControlName="descriptionCtrl"
                ></textarea>
              </mat-form-field>
            </mat-card>

            <div class="flex-box">
              <button
                mat-raised-button
                type="button"
                color="primary"
                matStepperNext
              >
                Siguiente
              </button>
            </div>
          </mat-step>

          <mat-step
            formGroupName="1"
            [stepControl]="formArray.get([1])"
            errorMessage="Campos incompletos."
          >
            <ng-template matStepLabel
              >Referencias, presentaciones y precios</ng-template
            >

            <!--        {{ formGroup.value | json }}-->

            <div formArrayName="presentations">
              <div
                *ngFor="let p of presentationsForm.controls; let i = index"
                [formGroupName]="i"
              >
                <mat-card class="sub-field">
                  <div *ngIf="mode === 'edit'; else elseBlock">
                    <!--                                Toogle buttons-->

                    <mat-button-toggle-group
                      [value]="type_values[i]"
                      formControlName="presentationType"
                    >
                      <mat-button-toggle
                        value="unidades"
                        (click)="onButtonPresentation('unidades', i)"
                        [checked]="type_values[i] === 'unidades'"
                        >Unidades
                      </mat-button-toggle>
                      <mat-button-toggle
                        value="peso"
                        (click)="onButtonPresentation('peso', i)"
                        [checked]="type_values[i] === 'peso'"
                        >Peso
                      </mat-button-toggle>

                      <mat-button-toggle
                        value="juego"
                        (click)="onButtonPresentation('juego', i)"
                        [checked]="type_values[i] === 'juego'"
                        >Peso
                      </mat-button-toggle>
                    </mat-button-toggle-group>

                    <!--              Selector-->

                    <!--                <div-->
                    <!--                  *ngIf="presentationsForm.at(i)['controls']['presentationType'].value === 'unidades'; else elseSelectorUnitsTypeBlock">-->
                    <!--                  <mat-form-field appearance="fill">-->
                    <!--                    <mat-label>Presentación</mat-label>-->
                    <!--                    <mat-select required formControlName="presentationQuantity" [value]="quantity_values[i]">-->
                    <!--                      <mat-option *ngFor="let p of presentationUnitsList" [value]="p">-->
                    <!--                        {{ p }}-->
                    <!--                      </mat-option>-->
                    <!--                    </mat-select>-->
                    <!--                  </mat-form-field>-->
                    <!--                </div>-->

                    <!--                <ng-template #elseSelectorUnitsTypeBlock>-->
                    <!--                  <mat-form-field appearance="fill">-->
                    <!--                    <mat-label>Presentación</mat-label>-->
                    <!--                    <mat-select required formControlName="presentationQuantity" [value]="quantity_values[i]">-->
                    <!--                      <mat-option *ngFor="let p of presentationWeightList" [value]="p">-->
                    <!--                        {{p}}-->
                    <!--                      </mat-option>-->
                    <!--                    </mat-select>-->
                    <!--                  </mat-form-field>-->
                    <!--                </ng-template>-->

                    <!-- TESTINT -->

                    <div
                      *ngIf="
                        presentationsForm.at(i)['controls']['presentationType']
                          .value !== '';
                        else elseSelectorBlock
                      "
                    >
                      <div
                        *ngIf="
                          presentationsForm.at(i)['controls'][
                            'presentationType'
                          ].value === 'unidades';
                          else elseSelectorUnitsTypeBlock
                        "
                      >
                        <mat-form-field appearance="fill">
                          <mat-label>Presentación</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="presentationQuantity"
                          />
                        </mat-form-field>
                      </div>

                      <ng-template #elseSelectorUnitsTypeBlock>
                        <mat-form-field appearance="fill">
                          <mat-label>Presentación</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="presentationQuantity"
                          />
                        </mat-form-field>
                      </ng-template>
                    </div>

                    <ng-template #elseSelectorBlock>
                      <mat-form-field appearance="fill">
                        <mat-label>Presentación</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="presentationQuantity"
                        />
                      </mat-form-field>
                    </ng-template>

                    <!-- TESTING -->

                    <div class="row">
                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Código de barras</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="barCode"
                            [ngModel]="barCodes[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Referencia</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="reference"
                            [ngModel]="references[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Precio (0.00)</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="price"
                            [ngModel]="p_values[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Precio PVP (0.00)</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="pvpPrice"
                            [ngModel]="pvp_values[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Largo (cm)</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="x"
                            [ngModel]="x_values[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Ancho (cm)</mat-label>

                          <input
                            matInput
                            type="number"
                            formControlName="y"
                            [ngModel]="y_values[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Profundidad (cm)</mat-label>

                          <input
                            matInput
                            type="number"
                            formControlName="z"
                            [ngModel]="z_values[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Peso (gr)</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="w"
                            [ngModel]="w_values[i]"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Cantidad por caja master</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="masterBox"
                            [ngModel]="masterBoxPresentations[i]"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <!--              ELSE BLOCK-->
                  <ng-template #elseBlock>
                    <mat-button-toggle-group formControlName="presentationType">
                      <mat-button-toggle
                        value="unidades"
                        (click)="onButtonPresentation('unidades', i)"
                        >Unidades
                      </mat-button-toggle>
                      <mat-button-toggle
                        value="peso"
                        (click)="onButtonPresentation('peso', i)"
                        >Peso
                      </mat-button-toggle>
                      <mat-button-toggle
                        value="juego"
                        (click)="onButtonPresentation('juego', i)"
                        >Juego
                      </mat-button-toggle>
                    </mat-button-toggle-group>

                    <!--              Selector-->

                    <div
                      *ngIf="
                        presentationsForm.at(i)['controls']['presentationType']
                          .value !== '';
                        else elseSelectorBlock
                      "
                    >
                      <div
                        *ngIf="
                          presentationsForm.at(i)['controls'][
                            'presentationType'
                          ].value === 'unidades';
                          else elseSelectorUnitsTypeBlock
                        "
                      >
                        <mat-form-field appearance="fill">
                          <mat-label>Presentación</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="presentationQuantity"
                          />
                        </mat-form-field>
                      </div>

                      <ng-template #elseSelectorUnitsTypeBlock>
                        <mat-form-field appearance="fill">
                          <mat-label>Presentación</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="presentationQuantity"
                          />
                        </mat-form-field>
                      </ng-template>
                    </div>

                    <ng-template #elseSelectorBlock>
                      <mat-form-field appearance="fill">
                        <mat-label>Presentación</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="presentationQuantity"
                        />
                      </mat-form-field>
                    </ng-template>

                    <div class="row">
                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Código de barras</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="barCode"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Referencia</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="reference"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Precio (0.00)</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="price"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Precio PVP (0.00)</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="pvpPrice"
                          />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Largo (cm)</mat-label>
                          <input matInput type="number" formControlName="x" />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Ancho (cm)</mat-label>

                          <input matInput type="number" formControlName="y" />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Profundidad (cm)</mat-label>

                          <input matInput type="number" formControlName="z" />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Peso (gr)</mat-label>
                          <input matInput type="number" formControlName="w" />
                        </mat-form-field>
                      </div>

                      <div class="col-md-3">
                        <mat-form-field>
                          <mat-label>Cantidad por caja master</mat-label>
                          <input
                            matInput
                            type="number"
                            formControlName="masterBox"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-template>
                </mat-card>
              </div>
            </div>

            <div class="add-delete-buttons">
              <button
                type="button"
                class="btn btn-success"
                (click)="onAddProductPresentation('2')"
              >
                Agregar
              </button>
              <button
                type="button"
                class="btn btn-danger"
                [disabled]="!isValid()"
                (click)="deletePresentation()"
              >
                Eliminar
              </button>
            </div>

            <div class="flex-box">
              <button
                mat-raised-button
                color="primary"
                type="button"
                matStepperPrevious
              >
                Atrás
              </button>
              <button
                mat-raised-button
                color="primary"
                type="button"
                matStepperNext
              >
                Siguiente
              </button>
            </div>
          </mat-step>

          <!--        Form for setting presentation, dimensions and prices-->

          <mat-step formGroupName="2" [stepControl]="formArray.get([2])">
            <ng-template matStepLabel>Photos y videos</ng-template>

            <div class="row">
              <div class="col-md-4">
                <mat-card class="sub-field">
                  <div class="centered-item">
                    <h3>Fotografía principal</h3>
                  </div>
                  <div class="">
                    <div
                      class="centered-item"
                      *ngIf="
                        imagePreview1 !== '' &&
                        imagePreview1 !== null &&
                        formGroup.get('formArray')['controls'][2].get('image1')
                          .valid
                      "
                    >
                      <img [src]="imagePreview1" alt="" class="img-thumbnail" />
                    </div>
                    <div class="centered-item">
                      <button
                        type="button"
                        class="btn btn-success"
                        (click)="imagePicker1.click()"
                      >
                        Cargar Foto
                      </button>
                      <input
                        type="file"
                        #imagePicker1
                        (change)="onImagePicked($event, 'image1')"
                      />
                    </div>
                  </div>
                </mat-card>
              </div>

              <div class="col-md-4">
                <mat-card class="sub-field">
                  <div class="centered-item">
                    <h3>Fotografía 2</h3>
                  </div>
                  <div class="">
                    <div
                      class="centered-item"
                      *ngIf="
                        imagePreview2 !== '' &&
                        imagePreview2 !== null &&
                        formGroup.get('formArray')['controls'][2].get('image2')
                          .valid
                      "
                    >
                      <img [src]="imagePreview2" alt="" class="img-thumbnail" />
                    </div>
                    <div class="centered-item">
                      <button
                        type="button"
                        class="btn btn-success"
                        (click)="imagePicker2.click()"
                      >
                        Cargar Foto
                      </button>
                      <input
                        type="file"
                        #imagePicker2
                        (change)="onImagePicked($event, 'image2')"
                      />
                    </div>
                  </div>
                </mat-card>
              </div>

              <div class="col-md-4">
                <mat-card class="sub-field">
                  <div class="centered-item">
                    <h3>Fotografía 3</h3>
                  </div>
                  <div class="">
                    <div
                      class="centered-item"
                      *ngIf="
                        imagePreview3 !== '' &&
                        imagePreview3 !== null &&
                        formGroup.get('formArray')['controls'][2].get('image3')
                          .valid
                      "
                    >
                      <img [src]="imagePreview3" alt="" class="img-thumbnail" />
                    </div>
                    <div class="centered-item">
                      <button
                        type="button"
                        class="btn btn-success"
                        (click)="imagePicker3.click()"
                      >
                        Cargar Foto
                      </button>
                      <input
                        type="file"
                        #imagePicker3
                        (change)="onImagePicked($event, 'image3')"
                      />
                    </div>
                  </div>
                </mat-card>
              </div>

              <div class="col-md-4">
                <mat-card class="sub-field">
                  <div class="centered-item">
                    <h3>Fotografía 4</h3>
                  </div>
                  <div class="">
                    <div
                      class="centered-item"
                      *ngIf="
                        imagePreview4 !== '' &&
                        imagePreview4 !== null &&
                        formGroup.get('formArray')['controls'][2].get('image4')
                          .valid
                      "
                    >
                      <img [src]="imagePreview4" alt="" class="img-thumbnail" />
                    </div>
                    <div class="centered-item">
                      <button
                        type="button"
                        class="btn btn-success"
                        (click)="imagePicker4.click()"
                      >
                        Cargar Foto
                      </button>
                      <input
                        type="file"
                        #imagePicker4
                        (change)="onImagePicked($event, 'image4')"
                      />
                    </div>
                  </div>
                </mat-card>
              </div>

              <div class="col-md-4">
                <mat-card class="sub-field">
                  <div class="centered-item">
                    <h3>Fotografía 5</h3>
                  </div>
                  <div class="">
                    <div
                      class="centered-item"
                      *ngIf="
                        imagePreview5 !== '' &&
                        imagePreview5 !== null &&
                        formGroup.get('formArray')['controls'][2].get('image5')
                          .valid
                      "
                    >
                      <img [src]="imagePreview5" alt="" class="img-thumbnail" />
                    </div>
                    <div class="centered-item">
                      <button
                        type="button"
                        class="btn btn-success"
                        (click)="imagePicker5.click()"
                      >
                        Cargar Foto
                      </button>
                      <input
                        type="file"
                        #imagePicker5
                        (change)="onImagePicked($event, 'image5')"
                      />
                    </div>
                  </div>
                </mat-card>
              </div>

              <div class="col-md-4">
                <mat-card class="sub-field">
                  <div class="centered-item">
                    <h3>Fotografía 6</h3>
                  </div>
                  <div class="">
                    <div
                      class="centered-item"
                      *ngIf="
                        imagePreview6 !== '' &&
                        imagePreview6 !== null &&
                        formGroup.get('formArray')['controls'][2].get('image6')
                          .valid
                      "
                    >
                      <img [src]="imagePreview6" alt="" class="img-thumbnail" />
                    </div>
                    <div class="centered-item">
                      <button
                        type="button"
                        class="btn btn-success"
                        (click)="imagePicker6.click()"
                      >
                        Cargar Foto
                      </button>
                      <input
                        type="file"
                        #imagePicker6
                        (change)="onImagePicked($event, 'image6')"
                      />
                    </div>
                  </div>
                </mat-card>
              </div>

              <div class="col-md-4">
                <mat-card class="sub-field">
                  <div class="centered-item">
                    <h3>Video</h3>
                  </div>

                  <div
                    class="centered-item"
                    *ngIf="
                      videoPreview !== '' &&
                      safeVideoUrl !== null &&
                      videoPreview !== 'null' &&
                      formGroup.get('formArray')['controls'][2].get('video')
                        .valid &&
                      formGroup.get('formArray')['controls'][2].get('video') !==
                        null
                    "
                  >
                    <iframe
                      width="180"
                      height="180"
                      [src]="getSafeUrl()"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>

                  <div
                    class="centered-item"
                    *ngIf="
                      videoPreview !== 'null' && videoPreview !== '';
                      else elseVideoBlock
                    "
                  >
                    <mat-form-field>
                      <mat-label>Url video</mat-label>
                      <input
                        matInput
                        name="producer"
                        [ngModel]="product?.videoUrl"
                        formControlName="video"
                      />
                      <!--                      <input matInput name="producer"  formControlName="video">-->
                    </mat-form-field>
                  </div>
                  <ng-template #elseVideoBlock>
                    <mat-form-field>
                      <mat-label>Url video</mat-label>
                      <input matInput formControlName="video" />
                    </mat-form-field>
                  </ng-template>
                </mat-card>
              </div>
            </div>

            <div>
              <div class="flex-box">
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  matStepperPrevious
                >
                  Atrás
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  matStepperNext
                >
                  Siguiente
                </button>
              </div>
            </div>
          </mat-step>

          <!--        Form for uploading photos and videos -->
          <mat-step>
            <ng-template matStepLabel>Finalizar</ng-template>
            <!--                {{ formGroup.value | json }}-->

            <!--        <div class="container">-->
            <!--          <div class="inline">-->
            <!--            <h3>Código de barras: </h3>-->
            <!--          </div>-->
            <!--          <div class="inline">-->
            <!--            <p>{{formGroup.value.formArray[0].masterBox}}</p>-->
            <!--          </div>-->
            <!--        </div>-->

            <div class="container">
              <!--          <div>-->
              <!--            <div class="inline">-->
              <!--              <h3>Referencia: </h3>-->
              <!--            </div>-->
              <!--            <div class="inline">-->
              <!--              <p>{{formGroup.value.formArray[0].refCtrl}}</p>-->
              <!--            </div>-->
              <!--          </div>-->

              <div>
                <div class="inline">
                  <h3>Producto:</h3>
                </div>
                <div class="inline">
                  <p>{{ formGroup.value.formArray[0].nameCtrl }}</p>
                </div>
              </div>

              <div>
                <div class="inline">
                  <h3>Descripción:</h3>
                </div>
                <div class="inline">
                  <p>{{ formGroup.value.formArray[0].descriptionCtrl }}</p>
                </div>
              </div>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>

                    <th scope="col">C. Barras</th>
                    <th scope="col">Referencia</th>
                    <th scope="col">Caja master</th>

                    <th scope="col">Tipo de presentación</th>
                    <th scope="col">Cantidad por presentación</th>
                    <th scope="col">Largo (cm)</th>
                    <th scope="col">Ancho (cm)</th>
                    <th scope="col">Profundidad (cm)</th>
                    <th scope="col">Peso (gr)</th>
                    <th scope="col">Precio ($)</th>
                    <th scope="col">Precio PVP ($)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let p of formGroup.value.formArray[1].presentations;
                      let i = index
                    "
                  >
                    <th scope="row">{{ i + 1 }}</th>

                    <td>{{ p.barCode }}</td>
                    <td>{{ p.reference }}</td>
                    <td>{{ p.masterBox }}</td>

                    <td>{{ p.presentationType }}</td>
                    <td>{{ p.presentationQuantity }}</td>
                    <td>{{ p.x }}</td>
                    <td>{{ p.y }}</td>
                    <td>{{ p.z }}</td>
                    <td>{{ p.w }}</td>
                    <td>{{ p.price }}</td>
                    <td>{{ p.pvpPrice }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <h3 class="format-header">Contenido multimedia:</h3>
            </div>

            <div>
              <div
                class="inline-img"
                *ngIf="imagePreview1 !== '' && imagePreview1 !== null"
              >
                <div class="centered-item before-submitting">
                  <img [src]="imagePreview1" alt="" class="img-thumbnail" />
                </div>
              </div>

              <div
                class="inline-img"
                *ngIf="imagePreview2 !== '' && imagePreview2 !== null"
              >
                <div class="centered-item before-submitting">
                  <img [src]="imagePreview2" alt="" class="img-thumbnail" />
                </div>
              </div>

              <div
                class="inline-img"
                *ngIf="
                  formGroup.value.formArray[2].video !== '' &&
                  formGroup.value.formArray[2].video !== 'null'
                "
              >
                <div class="centered-item before-submitting">
                  <mat-icon>play_circle_outline</mat-icon>
                </div>
              </div>
            </div>

            <div class="flex-box">
              <button
                mat-raised-button
                type="button"
                color="primary"
                matStepperPrevious
              >
                Atrás
              </button>
              <button
                mat-raised-button
                type="button"
                color="primary"
                [disabled]="mode !== 'create'"
                (click)="stepper.reset()"
              >
                Resetear
              </button>
              <button mat-raised-button color="primary" type="submit">
                Crear
              </button>
            </div>
          </mat-step>

          <!--        Part for showing results and submitting the form -->
        </mat-horizontal-stepper>
      </form>
    </mat-card>
  </div>
</div>
