<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">

    <section class="mat-typography title-group">
      <span class="title-group"><a mat-button routerLink="/dashboard" routerLinkActive="mat-accent"><h2>Órdenes</h2></a></span>

<!--      -->
<!--      <span class="spacer "></span>-->
<!--      <ul class="title-group">-->
<!--        <li>-->
<!--          <form class="form-inline">-->
<!--            <div class="search-bar">-->
<!--              <input type="text"-->
<!--                     placeholder=" Buscar cliente por nombres"-->
<!--                     aria-label="Number"-->
<!--                     class="ng-pristine ng-invalid ng-invalid-required ng-touched"-->
<!--                     [formControl]="myControl"-->
<!--                     [matAutocomplete]="auto">-->
<!--              <mat-autocomplete #auto="matAutocomplete">-->
<!--                <mat-option *ngFor="let option of filteredOptions | async; let i = index" [value]="option" >-->
<!--                  {{option}}-->
<!--                </mat-option>-->
<!--              </mat-autocomplete>-->
<!--              <button mat-button size="sm" color="accent" type="submit" (click)="searchClient([myControl.value])">Buscar</button>-->
<!--            </div>-->
<!--          </form>-->
<!--          &lt;!&ndash;        <a mat-button routerLink="/usuarios/crear" routerLinkActive="mat-accent">Crear</a>&ndash;&gt;-->
<!--        </li>-->
<!--      </ul>-->


      <mat-divider></mat-divider>


<!--      &lt;!&ndash;    ACCORDEON FOR A SEARCHED CLIENT. JUST FOR SEARCHED&ndash;&gt;-->
<!--      <div class="some-margin">-->
<!--        <mat-accordion multi="true" *ngIf="hasFound" >-->
<!--          <mat-expansion-panel class="founded-panel">-->
<!--            <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">-->
<!--              <div class="container">-->
<!--                <div class="one">-->
<!--                  RUC: {{searchedClient.ruc}}-->
<!--                </div>-->
<!--                <div class="two">-->
<!--                  Nombres: {{searchedClient.names}} {{searchedClient.lastNames}}-->
<!--                </div>-->
<!--                <div class="three">-->
<!--                  Descuento: {{searchedClient.discount}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </mat-expansion-panel-header>-->
<!--            <div class="center-mat">-->
<!--              <mat-divider></mat-divider>-->
<!--            </div>-->
<!--            <div class="draw-main">-->
<!--              <div class="row just-draw">-->
<!--                &lt;!&ndash;          Block for saving GENERALITIES OF THE USER&ndash;&gt;-->
<!--                <div class="col-md-12 just-draw">-->
<!--                  <div class="price just-draw container row">-->
<!--                    <div class="grid-container-subitem">-->
<!--                      <div class="grid-item-subitem">Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.email}}</div>-->
<!--                    </div>-->
<!--                  </div>-->


<!--                  <div class="center-mat">-->
<!--                    <mat-divider></mat-divider>-->
<!--                  </div>-->

<!--                  <div class="price just-draw container row">-->
<!--                    <div class="grid-container-subitem col-md-6">-->
<!--                      <div class="grid-item-subitem">Teléfono 1: &nbsp;&nbsp; {{searchedClient.phone1}}</div>-->
<!--                    </div>-->
<!--                    <div class="grid-container-subitem col-md-6">-->
<!--                      <div class="grid-item-subitem">Teléfono 2: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.phone2}}</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="price just-draw container row">-->
<!--                    <div class="grid-container-subitem col-md-6">-->
<!--                      <div class="grid-item-subitem">Provincia: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.province}}</div>-->
<!--                    </div>-->
<!--                    <div class="grid-container-subitem col-md-6">-->
<!--                      <div class="grid-item-subitem">Cantón: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.city}}</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="price just-draw container row">-->
<!--                    <div class="grid-container-subitem">-->
<!--                      <div class="grid-item-subitem">Dirección: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.address}}</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <mat-divider></mat-divider>-->
<!--                  <div class="price just-draw container row">-->
<!--                    <div class="grid-container-subitem col-md-6">-->
<!--                      <div class="grid-item-subitem">Sexo: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.sex}}</div>-->
<!--                    </div>-->
<!--                    <div class="grid-container-subitem col-md-6">-->
<!--                      <div class="grid-item-subitem">Nacimiento: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.birthday.split('T')[0]}}</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->


<!--            <mat-action-row>-->
<!--              <a mat-button color="primary" [routerLink]="['/usuarios/editar', searchedClient._id]">Editar</a>-->
<!--              <button mat-button color="warn" (click)="openDialog(searchedClient._id)">Eliminar</button>-->
<!--            </mat-action-row>-->
<!--          </mat-expansion-panel>-->

<!--        </mat-accordion>-->

<!--      </div>-->


      <!--    <mat-divider></mat-divider>-->

    </section>

    <mat-spinner color="accent"  *ngIf="isLoading"></mat-spinner>
    <mat-accordion multi="true" *ngIf="orders.length > 0 && !isLoading">
      <mat-expansion-panel *ngFor="let order of orders; let i = index">
        <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
          <div class="container">
            <div class="one">
              No: {{order.orderNumber}}
            </div>
            <div class="two">
              RUC: $ {{order.shoppingCar?.userRUC}}
            </div>
            <div class="three">
              Fecha: {{order.date}} {{order.time}}
            </div>
            <div class="four">
              Total: $ {{order.total}}
            </div>
          </div>
        </mat-expansion-panel-header>

        <div class="center-mat">
          <mat-divider></mat-divider>
        </div>

        <div class="draw-main">
          <div class="row just-draw">
            <div class="col-md-12 just-draw">
              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Nombres: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; {{order?.shoppingCar?.userNames}}  {{order?.shoppingCar?.lastNames}}</div>
                </div>
              </div>

              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">RUC: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; {{order?.shoppingCar?.userRUC }}</div>
                </div>
              </div>



              <div class="center-mat">
                <mat-divider></mat-divider>
              </div>

              <div class="price just-draw container row">
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Teléfono 1: &nbsp;&nbsp; {{order?.shoppingCar?.userPhone1}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Teléfono 2: &nbsp;&nbsp;&nbsp;&nbsp; {{order?.shoppingCar?.userPhone2}}</div>
                </div>
              </div>
              <div class="price just-draw container row">
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Provincia: &nbsp;&nbsp;&nbsp;&nbsp; {{order?.shoppingCar?.userProvince}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Cantón: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{order?.shoppingCar?.userCity}}</div>
                </div>
              </div>
              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Dirección: &nbsp;&nbsp;&nbsp;&nbsp; {{order?.shoppingCar?.userAddress}}</div>
                </div>
              </div>


              <mat-divider></mat-divider>
              <div class="price just-draw container row">
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Sub total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$ {{order?.subtotal}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Descuento {{order?.shoppingCar?.userDiscount}} %: &nbsp;&nbsp;&nbsp;&nbsp; $ {{order?.discount}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">IVA {{order?.shoppingCar?.iva}} %: &nbsp;&nbsp;&nbsp;&nbsp; $ {{order?.taxes}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Total: $ {{order?.total}}</div>
                </div>
              </div>


              <div *ngFor="let item of order?.shoppingCar?.carItems; let i = index">
                <mat-divider></mat-divider>
                <h4>Item {{i + 1}}</h4>

                <div class="price just-draw container row" >
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Producto: {{item.product}}</div>
                  </div>
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Código de barras: {{item.barCode}} </div>
                  </div>
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Presentación: {{item.presentation}}</div>
                  </div>
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Cantidad:  {{item.quantity}}</div>
                  </div>
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Precio: $ {{item.price}}</div>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>


<!--        <mat-action-row>-->
<!--          <a mat-button color="primary" [routerLink]="['/usuarios/editar', user._id]">Editar</a>-->
<!--          <button mat-button color="warn" (click)="openDialog(user._id)">Eliminar</button>-->
<!--        </mat-action-row>-->
      </mat-expansion-panel>

    </mat-accordion>

    <mat-paginator [length]="totalOrders" [pageSize]="ordersPerPage" [pageSizeOptions]="pageSizeOptions" (page)="onChangedPage($event)"
                   *ngIf="orders.length > 0"></mat-paginator>
    <p *ngIf="orders.length <= 0 && !isLoading" class="info-text">No hay ordenes!</p>

  </div>
</div>
