import {User} from "./user.model"
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class UserService {
  private users: User[] = [];
  private usersUpdated = new Subject<User[]>();
  private locations: [];
  clientsListNames = [];
  clientsListListener = new Subject<{ clientsList: []}>()
  GESTION_URUARIOS_URL = environment.apiUrl + '/gestion-usuarios';
  UBICACIONES = environment.apiUrl + '/gestion-usuarios/ubicaciones';
  USUARIOS_LISTA_TOSEARCH_URL = environment.apiUrl + '/other-services/get-clients-list';

  constructor(private httpClient: HttpClient, private router: Router) {
  }

  getClientsListListener(){
    return this.clientsListListener.asObservable();
  }

  getUsers(productsPerPage: number, currentPage: number){
    const queryParams = `?pageSize=${productsPerPage}&page=${currentPage}`;

    this.httpClient.get<{message: string, users: User[]}>(this.GESTION_URUARIOS_URL + queryParams)
      .subscribe((usersData) => {
        this.users = usersData.users;
        this.usersUpdated.next([...this.users]);
      });
  }

  getLocations(){

    return this.httpClient.get<{locations: null}>(this.UBICACIONES)
  }

  getUser(id: string){
    return this.httpClient.get<{_id: null, sex: null, password: null, names: null, lastNames: null,
    province: null, city: null, address: null, phone1: null, phone2: null, email: null, category: null,
    discount: null, birthday: null, ruc: null}>(this.GESTION_URUARIOS_URL + '/' + id)
  }

  getUserUpdateListener(){
    return this.usersUpdated.asObservable();
  }


  // addUser(user) {
  //   this.httpClient.post<{ message: string, user: User}>(this.GESTION_URUARIOS_URL, user)
  //     .subscribe((responseData) => {
  //       console.log(responseData.message);
  //       user._id = responseData.user._id;
  //
  //       this.users.push(user);
  //       this.usersUpdated.next([...this.users])
  //     })
  //   this.router.navigate(['usuarios/listar']);
  // }

  updateUser(id: string, user){
    // console.log(id)
    this.httpClient.put(this.GESTION_URUARIOS_URL + '/' + id, user)
      .subscribe(response => {
        // console.log(response)
        const updatedUsers = [...this.users];
        const oldUserIndex = updatedUsers.findIndex(u => u._id === user._id)
        const updatedUser: User = {
          _id: id,
          sex: user.sex,
          birthday: user.birthday,
          ruc: user.ruc,
          password: user.password,
          names: user.names,
          lastNames: user.lastNames,
          province: user.province,
          city: user.city,
          address: user.address,
          phone1: user.phone1,
          phone2: user.phone2,
          email: user.email,
          category: user.category,
          discount: user.discount,
        }

        updatedUsers[oldUserIndex] = updatedUser;
        this.users = updatedUsers;
        this.usersUpdated.next([...this.users])
        this.router.navigate(['usuarios/listar']);
      })
  }

  deleteUser(userId: string){
    this.httpClient.delete<{message: string}>(this.GESTION_URUARIOS_URL + '/' + userId)
      .subscribe((response) => {
        // console.log(response.message);
        this.users = this.users.filter(user => user._id !== userId);
        this.usersUpdated.next([...this.users]);
      })
  }

  getListOfClientsNamesToSearch(){
    this.httpClient.get<{clientsList: []}>(this.USUARIOS_LISTA_TOSEARCH_URL)
      .subscribe((respData) => {
        // console.log('Lista de nombres de clientes recuperada')
        this.clientsListNames = respData.clientsList;
        this.clientsListListener.next({
          clientsList: respData.clientsList
        })
      })
  }


}
