import {Component, OnInit} from "@angular/core";
import {User} from "../user.model";
import {
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {UserService} from "../user.service";
import { AdminService} from "../../admins/admin.service";
import {CustomValidationService} from "../../../../../projects/store/src/app/auth/custom-validation.service";
import {ActivatedRoute, ParamMap} from "@angular/router";

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css'],
})
export class UserCreateComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder,
              public userService: UserService,
              public route: ActivatedRoute, private adminService: AdminService,
              private passwValidator: CustomValidationService) {}

  userFormGroup: FormGroup;
  user: User;
  mode = 'create';
  private userId: string;
  isLoading = false;
  locations;
  currentProvince: string;
  currentCity: string;
  adminIsAuthenticated =false;
  sex:string;

  provinceList: string [] = []
  citiesList: string[] = []

  testingDate: string;

  userType: string[] = ['Cliente', 'Administrador'];


  ngOnInit() {
    this.adminIsAuthenticated = this.adminService.getIsLogged();


    this.userFormGroup = this._formBuilder.group({
      names: ['',[Validators.required], []],
      ruc: [null,[], []],
      sex: ['',[Validators.required], []],
      birthday: ['',[Validators.required], []],
      lastNames: ['',[Validators.required], []],
      province: ['',[Validators.required], []],
      city: ['',[Validators.required], []],
      address: ['',[Validators.required], []],
      phone1: ['',[Validators.required], []],
      phone2: ['',[], []],
      email: ['',[Validators.required], []],  //PK
      category: ['',[], []],
      discount: ['',[], []],
      password: ['', [Validators.required], []],
      passwordValidate: ['',[Validators.required],[]]
    }, {
        validator: [this.passwValidator.passwordValidator("password"),
          this.passwValidator.passwordMatchValidator("password", "passwordValidate"),
        ],
      },
    );

    this.userService.getLocations().subscribe(locData => {
      this.locations = locData;
      for(let i = 0; i < this.locations.length; i++){
        this.provinceList.push(this.locations[i]['provincia']);
      }
    })

    this.isLoading = true;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.isLoading = false;

      if(paramMap.has('userId')){
        this.mode = 'edit';
        this.userId = paramMap.get('userId');

        this.userService.getUser(this.userId).subscribe(userData => {
          this.user = {
            _id: userData._id,
            ruc: userData.ruc,
            sex: userData.sex,
            birthday: userData.birthday,
            password: userData.password,
            names: userData.names,
            lastNames: userData.lastNames,
            province: userData.province,
            city: userData.city,
            address: userData.address,
            phone1: userData.phone1,
            phone2: userData.phone2,
            email: userData.email,
            category: null,
            discount: userData.discount,
          }
          this.userFormGroup['controls']['password'].setValue(userData.password);
          this.userFormGroup['controls']['passwordValidate'].setValue(userData.password);

          this.userFormGroup['controls']['names'].setValue(userData.names);
          this.userFormGroup['controls']['lastNames'].setValue(userData.lastNames);
          this.userFormGroup['controls']['province'].setValue(userData.province);
          this.userFormGroup['controls']['city'].setValue(userData.city);

          this.userFormGroup['controls']['sex'].setValue(userData.sex);
          this.userFormGroup['controls']['ruc'].setValue(userData.ruc);
          this.userFormGroup['controls']['birthday'].setValue(userData.birthday);

          this.userFormGroup['controls']['address'].setValue(userData.address);
          this.userFormGroup['controls']['phone1'].setValue(userData.phone1);
          this.userFormGroup['controls']['phone2'].setValue(userData.phone2);
          this.userFormGroup['controls']['email'].setValue(userData.email);
          this.userFormGroup['controls']['category'].setValue(null);
          this.userFormGroup['controls']['discount'].setValue(userData.discount);

          for(var i = 0; i < this.locations.length; i++){
            if(this.locations[i].provincia === this.user.province){
              this.citiesList = this.locations[i].cantones;
            }
          }
        });
      } else {
        this.mode = 'create';

        this.sex = 'mujer';
        this.userFormGroup['controls']['sex'].setValue('mujer');

        this.userId = null;
      }
    })

  }

  getCities(index){
    this.citiesList = this.locations[index].cantones
  }

  onSaveUser(){
    if(this.userFormGroup.invalid){
      return;
    }
    this.isLoading = true;

    const user = {
      _id: null,
      email: this.userFormGroup.value.email,
      sex: this.userFormGroup.value.sex,
      birthday: this.userFormGroup.value.birthday,
      ruc: this.userFormGroup.value.ruc,
      password: this.userFormGroup.value.password,
      names: this.userFormGroup.value.names,
      lastNames: this.userFormGroup.value.lastNames,
      province: this.userFormGroup.value.province,
      city: this.userFormGroup.value.city,
      address: this.userFormGroup.value.address,
      phone1: this.userFormGroup.value.phone1,
      phone2: this.userFormGroup.value.phone2,
      category: null,
      discount: this.userFormGroup.value.discount,
    };
    if(this.mode === 'create'){
      // this.userService.addUser(user);
    } else {
      this.userService.updateUser(this.userId, user);
    }
    this.userFormGroup.reset()
  }


}
