import {Component, OnInit} from "@angular/core";
import {Admin} from "../admin.model";
import {
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {AdminService} from "../admin.service";

import {ActivatedRoute, ParamMap} from "@angular/router";
import {CustomValidationService} from "../../../../../projects/store/src/app/auth/custom-validation.service";

@Component({
  selector: 'app-admin-create',
  templateUrl: './admin-create.component.html',
  styleUrls: ['./admin-create.component.css'],
})
export class AdminCreateComponent implements OnInit {



  userFormGroup: FormGroup;
  user: Admin;
  mode = 'create';
  private adminId: string;
  isLoading = false;
  userType = ['1','2']
  isActive = true;
  adminIsAuthenticated = false;
  constructor(private _formBuilder: FormBuilder,
              public userService: AdminService,
              public route: ActivatedRoute,
              private passwValidator: CustomValidationService) {}

  ngOnInit() {

    this.adminIsAuthenticated = this.userService.getIsLogged();


    this.userFormGroup = this._formBuilder.group({
      isActive: [true,[Validators.required], []],

      names: ['',[Validators.required], []],
      // ced: [null,[], []],
      lastNames: ['',[Validators.required], []],
      category: ['',[], []],
      username: ['',[Validators.required], []],

      password: ['', [Validators.required], []],
      passwordValidate: ['',[Validators.required],[]]
    }, {
        validator: [this.passwValidator.passwordValidator("password"),
          this.passwValidator.passwordMatchValidator("password", "passwordValidate"),
        ],
      },
    );

    this.isLoading = true;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.isLoading = false;
      if(paramMap.has('userId')){
        this.mode = 'edit';
        this.adminId = paramMap.get('userId');

        this.userService.getUser(this.adminId).subscribe(userData => {
          this.user = {
            _id: userData._id,
            isActive: userData.isActive,
            ced: null,
            password: userData.password,
            names: userData.names,
            lastNames: userData.lastNames,
            category: null,
            username: userData.username,
          }
          this.userFormGroup['controls']['password'].setValue(userData.password);
          this.userFormGroup['controls']['passwordValidate'].setValue(userData.password);


          this.isActive = userData.isActive;
          this.userFormGroup['controls']['isActive'].setValue(userData.isActive);

          this.userFormGroup['controls']['names'].setValue(userData.names);
          this.userFormGroup['controls']['lastNames'].setValue(userData.lastNames);
          this.userFormGroup['controls']['ced'].setValue(null);
          this.userFormGroup['controls']['category'].setValue(null);
          this.userFormGroup['controls']['username'].setValue(userData.category);


        });
      } else {
        this.mode = 'create';
        this.adminId = null;
      }
    })

  }

  onSaveUser(){
    if(this.userFormGroup.invalid){
      return;
    }
    this.isLoading = true;

    const admin = {
      _id: null,
      ced: this.userFormGroup.value.ced,
      password: this.userFormGroup.value.password,
      names: this.userFormGroup.value.names,
      lastNames: this.userFormGroup.value.lastNames,
      category: this.userFormGroup.value.category,
      isActive: this.userFormGroup.value.isActive,
      username: this.userFormGroup.value.username
    };
    if(this.mode === 'create'){
      this.userService.addUser(admin);
    } else {
      this.userService.updateUser(this.adminId, admin);
    }
    this.userFormGroup.reset()
  }

}
