<script src="../category-list/category-list.component.ts"></script>
<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">

  <section class="mat-typography title-group">
    <span class="title-group"><a mat-button routerLink="/categorias/listar" routerLinkActive="mat-accent"><h2>Categorías</h2></a></span>
    <span class="spacer "></span>
    <ul class="title-group">

      <li>
        <a mat-button routerLink="/categorias/crear" routerLinkActive="mat-accent">Crear</a>
      </li>
    </ul>
    <mat-divider></mat-divider>
  </section>


  <mat-card>
    <mat-spinner color="accent" *ngIf="isLoading"></mat-spinner>




    <form [formGroup]="formGroup" (ngSubmit)="onSaveCategory(formGroup)"
          (keydown.enter)="$event.preventDefault()" *ngIf="!isLoading">

      <mat-card class="sub-field container">

        <mat-form-field>
          <mat-label>Nombre de la categoría</mat-label>
          <input matInput [ngModel]="category?.name" formControlName="nameCtrl">
        </mat-form-field>

        <div *ngIf="mode === 'edit'; else elseCharBlock">
          <div>
            <mat-slide-toggle (change)="onToggle()" [checked]="isAvailable" >Categoría disponible</mat-slide-toggle>
          </div>
        </div>

        <ng-template #elseCharBlock>
          <div>
            <mat-slide-toggle (change)="onToggle()" [checked]="isAvailable" >Categoría disponible</mat-slide-toggle>
          </div>
        </ng-template>

      </mat-card>

      <div class="col-md-12">
        <mat-card class="sub-field">
          <div  class="centered-item">
            <h3>Portada</h3>
            <button type="button" class="btn btn-success" (click)="imagePicker1.click()">Cargar Foto</button>
          </div>
          <div class="" >
            <div  class="centered-item" *ngIf="imagePreview1 !== '' && imagePreview1 !== null && formGroup.controls['image1'].valid">
              <img [src]="imagePreview1" alt="" class="img-thumbnail">
            </div>
            <div  class="centered-item">
              <input type="file" #imagePicker1 (change)="onImagePicked($event, 'image1')">
            </div>
          </div>
        </mat-card>
      </div>

      <div class="flex-box">
        <button mat-raised-button color="primary" type="submit">{{mode === 'edit' ? 'Editar' : 'Crear'}}</button>
      </div>

  </form>

</mat-card>

</div>
</div>
