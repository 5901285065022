import {Order} from "./order.model"

import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class OrdersService {
  private order: Order[] = [];
  maxOrders
  private ordersUpdated = new Subject<{ orders: Order[], orderCount: number }>();

  ORDERS_URL = environment.apiUrl + '/orders';

  constructor(private httpClient: HttpClient) {}

  getOrderUpdateListener(){
    return this.ordersUpdated.asObservable();
  }


  getOrders(ordersPerPage: number, currentPage: number){
    const queryParams = `?pageSize=${ordersPerPage}&page=${currentPage}`;

    this.httpClient.get<{message: string, orders: Order[], maxOrders: number}>(this.ORDERS_URL + queryParams)
      .subscribe((ordersData) => {
        this.order = ordersData.orders;
        this.maxOrders = ordersData.maxOrders
        this.ordersUpdated.next({orders: [...this.order], orderCount: this.maxOrders});
      });
  }
}
