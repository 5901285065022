<div class="outter-center" *ngIf="adminIsAuthenticated">
<div class="inner-center">

  <section class="mat-typography title-group">
    <span class="title-group"><a mat-button routerLink="/administradores/listar" routerLinkActive="mat-accent"><h2>Administradores</h2></a></span>
    <span class="spacer "></span>
    <ul class="title-group">
      <li>
        <a mat-button routerLink="/administradores/crear" routerLinkActive="mat-accent">Crear</a>
      </li>
    </ul>
    <mat-divider></mat-divider>
  </section>


  <mat-card>
    <mat-spinner color="accent" *ngIf="isLoading"></mat-spinner>
    <form [formGroup]="userFormGroup" (ngSubmit)="onSaveUser()" *ngIf="!isLoading">

<!--      {{userFormGroup.value | json}}-->

      <div class="container">
        <mat-card class="sub-field">
          <mat-label><h3>Información para cuenta administrativa</h3></mat-label>



          <div class="row container ">
            <div class="container">
              <mat-slide-toggle [checked]="isActive" formControlName="isActive">Activo</mat-slide-toggle>
            </div>
          </div>


          <div class="row container">

            <div class="col-md-6">
              <div *ngIf="mode !== 'create'; else elseNameBlock">
                <mat-form-field>
                  <mat-label>Nombres</mat-label>
                  <input matInput disabled type="text" [value]="user?.names">
                </mat-form-field>
              </div>
              <ng-template #elseNameBlock>
                <mat-form-field>
                  <mat-label>Nombres</mat-label>
                  <input matInput required type="text" formControlName="names">
                </mat-form-field>
              </ng-template>

            </div>

            <div class="col-md-6">

              <div *ngIf="mode !== 'create'; else elseLastNameBlock">
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input matInput disabled  type="text" [value]="user?.lastNames">
                </mat-form-field>
              </div>
              <ng-template #elseLastNameBlock>
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input matInput required  type="text" formControlName="lastNames">
                </mat-form-field>
              </ng-template>





            </div>

          </div>

<!--          <div class="container row">-->
<!--            <div class="col-md-12">-->
<!--              <mat-form-field>-->
<!--                <mat-label>Cédula</mat-label>-->
<!--                <input matInput required type="text" formControlName="ced" [value]="user?.ced">-->
<!--              </mat-form-field>-->
<!--            </div>-->
<!--          </div>-->

          <div class="container row">
            <div class="col-md-12">
              <div *ngIf="mode !== 'create'; else elseUsernameBlock">
                <mat-form-field>
                  <mat-label>Username</mat-label>
                  <input matInput disabled type="text" [value]="user?.username">
                </mat-form-field>
              </div>
              <ng-template #elseUsernameBlock>
                <mat-form-field>
                  <mat-label>Username</mat-label>
                  <input matInput required type="text" formControlName="username" [value]="user?.username">
                </mat-form-field>
              </ng-template>

            </div>
          </div>


<!--          <div class="col-md-12">-->
<!--            <mat-form-field appearance="fill">-->
<!--              <mat-label>Tipo de usuario</mat-label>-->
<!--              <mat-select required formControlName="category" [value]="user?.category" >-->
<!--                <mat-option *ngFor="let u of userType; let i = index" [value]="u">-->
<!--                  {{u}}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->


          <div class="container row" *ngIf="mode === 'create'">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Contraseña</mat-label>
                <input required  matInput type="text" formControlName="password">
              </mat-form-field>
            </div>
          </div>

          <div class="container row"  *ngIf="mode === 'create'">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>Confirmar contraseña</mat-label>
                <input matInput type="password" formControlName="passwordValidate">
              </mat-form-field>
            </div>
          </div>

        </mat-card>
      </div>

      <div class="flex-box container">
        <button mat-raised-button type="button" color="primary" [disabled]="mode !== 'create'" (click)="userFormGroup.reset()">Resetear</button>
        <button mat-raised-button color="primary" type="submit">Crear</button>
      </div>

    </form>


  </mat-card>

</div>

</div>
