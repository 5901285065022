import {Admin} from "./admin.model"
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthData} from "./auth-data.model";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AdminService {
  private token: string;
  private tokenTimer: number | undefined;
  private users: Admin[] = [];
  private usersUpdated = new Subject<Admin[]>();
  private authStatusListener = new Subject<boolean>();
  public username: string;
  private isLogged = false;
  ADMIN_LOGIN_URL = environment.apiUrl + '/administradores/login';
  ADMIN_URL = environment.apiUrl + '/administradores'
  constructor(private httpClient: HttpClient, private router: Router) {}

  getToken(){
    return this.token;
  }

  getIsLogged(){
    return this.isLogged;
  }

  getUsername(){
    return this.username;
  }

  getAuthStatusListener(){
    return this.authStatusListener.asObservable();
  }

  loginAdmin(username: string, password: string){
    const authData: AuthData = {username: username, password: password};
    this.httpClient.post<{token: string, expiresIn: number}>(this.ADMIN_LOGIN_URL, authData)
      .subscribe(response => {
        this.token = response.token;
        if(response.token){
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.username = username;
          this.isLogged = true;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          this.saveAuthData(this.token, expirationDate);
          this.router.navigate(['/dashboard']);
        }
      });
  }

  autoAuthUser(){
    const authInformation = this.getAuthData();

    if(!authInformation){
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if(expiresIn > 0){
      this.token = authInformation.token;
      this.isLogged = true;
      this.setAuthTimer(expiresIn / 1000);

      this.authStatusListener.next(true);
    }
  }

  logOutAdmin(){
    this.token = null;
    this.isLogged = false;
    this.authStatusListener.next(false);

    this.clearAuthData();
    this.router.navigate(['/login']);

  }

  private setAuthTimer(duration: number){
    this.tokenTimer = window.setTimeout(()=>{

      this.logOutAdmin();
    }, duration * 1000)
  }

  getUsers(){
    this.httpClient.get<{message: string, users: Admin[]}>(this.ADMIN_URL)
      .subscribe((usersData) => {
        this.users = usersData.users;
        this.usersUpdated.next([...this.users]);
      });
  }

  getUser(id: string){
    return this.httpClient.get<{_id: null, isActive: null, password: null, names: null, lastNames: null, category: null,
    ced: null, username: null}>(this.ADMIN_URL + '/' +id)
  }

  getUserUpdateListener(){
    return this.usersUpdated.asObservable();
  }

  addUser(user) {
    this.httpClient.post<{ message: string, user: Admin}>(this.ADMIN_URL, user)
      .subscribe((responseData) => {
        // console.log(responseData.message);

        user._id = responseData.user._id;

        this.users.push(user);
        this.usersUpdated.next([...this.users])
      })
    this.router.navigate(['administradores/listar']);
  }

  updateUser(id: string, user){
    this.httpClient.put(this.ADMIN_URL + '/' + id, user)
      .subscribe(response => {
        // console.log(response)
        const updatedUsers = [...this.users];
        const oldUserIndex = updatedUsers.findIndex(u => u._id === user._id)
        const updatedUser: Admin = {
          _id: id,
          username: user.username,
          isActive: user.isActive,
          ced: user.ced,
          password: user.password,
          names: user.names,
          lastNames: user.lastNames,
          category: user.category,
        }
        updatedUsers[oldUserIndex] = updatedUser;
        this.users = updatedUsers;
        this.usersUpdated.next([...this.users])
        this.router.navigate(['administradores/listar']);

      })
  }

  deleteUser(userId: string){
    this.httpClient.delete<{message: string}>(this.ADMIN_URL + '/' + userId)
      .subscribe((response) => {
        // console.log(response.message);
        this.users = this.users.filter(user => user._id !== userId);
        this.usersUpdated.next([...this.users]);
      })
  }

  private saveAuthData(token: string, expirationDate: Date){
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
  }

  private clearAuthData(){
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');

  }

  private  getAuthData(){
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    if(!token || !expirationDate){
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate)
    }
  }


}
