<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">
    <section class="mat-typography title-group">
      <span class="title-group"
        ><a
          mat-button
          routerLink="/usuarios/listar"
          routerLinkActive="mat-accent"
          ><h2>Clientes</h2></a
        ></span
      >
      <span class="spacer"></span>
      <ul class="title-group">
        <li>
          <!--        <a mat-button routerLink="/usuarios/crear" routerLinkActive="mat-accent">Crear</a>-->
        </li>
      </ul>
      <mat-divider></mat-divider>
    </section>

    <mat-card>
      <mat-spinner color="accent" *ngIf="isLoading"></mat-spinner>
      <form
        [formGroup]="userFormGroup"
        (ngSubmit)="onSaveUser()"
        *ngIf="!isLoading"
      >
        <!--      {{userFormGroup.value | json}}-->

        <div class="container">
          <mat-card class="sub-field">
            <mat-label><h3>Información personal</h3></mat-label>

            <div class="row container">
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Nombres</mat-label>
                  <input
                    matInput
                    required
                    type="text"
                    formControlName="names"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input
                    matInput
                    required
                    type="text"
                    formControlName="lastNames"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="container row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>RUC</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="ruc"
                    [value]="user?.ruc"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-6">
                <div>
                  <label id="example-radio-group-label">Sexo</label>
                </div>
                <div *ngIf="mode === 'create'; else elseSexBlock">
                  <mat-radio-group
                    aria-label="Sexo"
                    formControlName="sex"
                    [(ngModel)]="sex"
                  >
                    <mat-radio-button value="mujer">Mujer</mat-radio-button>
                    <mat-radio-button value="hombre">Hombre</mat-radio-button>
                  </mat-radio-group>
                </div>
                <ng-template #elseSexBlock>
                  <mat-radio-group
                    aria-label="Sexo"
                    formControlName="sex"
                    [value]="user?.sex"
                  >
                    <mat-radio-button value="mujer">Mujer</mat-radio-button>
                    <mat-radio-button value="hombre">Hombre</mat-radio-button>
                  </mat-radio-group>
                </ng-template>
              </div>

              <div class="col-md-6">
                <div *ngIf="mode === 'create'; else elseBirthBlock">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Fecha de nacimiento (mm/dd/aaaa)</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      formControlName="birthday"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon
                        >keyboard_arrow_down</mat-icon
                      >
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

                <ng-template #elseBirthBlock>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Fecha de nacimiento (mm/dd/aaaa)</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      formControlName="birthday"
                      [value]="user?.birthday"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon
                        >keyboard_arrow_down</mat-icon
                      >
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </ng-template>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Teléfono principal</mat-label>
                  <input
                    matInput
                    required
                    type="text"
                    formControlName="phone1"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Teléfono secundario (Opcional)</mat-label>
                  <input matInput type="text" formControlName="phone2" />
                </mat-form-field>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Provincia</mat-label>
                  <mat-select
                    required
                    formControlName="province"
                    [value]="user?.province"
                  >
                    <mat-option
                      *ngFor="let p of provinceList; let i = index"
                      [value]="p"
                      (click)="getCities(i)"
                    >
                      {{ p }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Ciudad</mat-label>
                  <mat-select
                    required
                    formControlName="city"
                    [value]="user?.city"
                  >
                    <mat-option
                      *ngFor="let c of citiesList; let i = index"
                      [value]="c"
                    >
                      {{ c }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="container row">
                <div class="col-md-12">
                  <mat-form-field>
                    <mat-label>Dirección</mat-label>
                    <input
                      required
                      matInput
                      type="text"
                      formControlName="address"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="container">
          <mat-card class="sub-field">
            <mat-label><h3>Datos de la cuenta</h3></mat-label>

            <!--          <div class="container row">-->

            <!--            <div class="col-md-12">-->
            <!--              <mat-form-field appearance="fill">-->
            <!--                <mat-label>Tipo de usuario</mat-label>-->
            <!--                <mat-select required formControlName="category" [value]="user?.category" >-->
            <!--                  <mat-option *ngFor="let u of userType; let i = index" [value]="u">-->
            <!--                    {{u}}-->
            <!--                  </mat-option>-->
            <!--                </mat-select>-->
            <!--              </mat-form-field>-->
            <!--            </div>-->
            <!--          </div>-->

            <div class="container row">
              <div class="col-md-12">
                <div *ngIf="mode === 'create'; else emailElseBlock">
                  <mat-form-field>
                    <mat-label>Email</mat-label>
                    <!--                <input matInput disabled  type="email"  >-->
                    <input
                      matInput
                      disabled
                      required
                      type="email"
                      formControlName="email"
                      email
                    />
                  </mat-form-field>
                </div>

                <ng-template #emailElseBlock>
                  <mat-form-field>
                    <mat-label>{{ user?.email }}</mat-label>
                    <input matInput disabled />
                  </mat-form-field>
                </ng-template>
              </div>
            </div>

            <div class="container row" *ngIf="mode === 'create'">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Contraseña</mat-label>
                  <input
                    required
                    matInput
                    type="text"
                    formControlName="password"
                  />
                </mat-form-field>
              </div>

              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Confirmar contraseña</mat-label>
                  <input
                    matInput
                    type="password"
                    formControlName="passwordValidate"
                  />
                </mat-form-field>
              </div>
            </div>

            <!--          <div class="container row">-->
            <!--            <div class="col-md-12">-->
            <!--              <mat-form-field>-->
            <!--                <mat-label>Confirmar contraseña</mat-label>-->
            <!--                <input matInput type="password" formControlName="passwordValidate">-->
            <!--              </mat-form-field>-->
            <!--            </div>-->
            <!--          </div>-->
          </mat-card>
        </div>

        <div class="container">
          <mat-card class="sub-field">
            <mat-label><h3>Información adicional</h3></mat-label>

            <div class="container row">
              <div class="col-md-12">
                <mat-form-field>
                  <mat-label>Descuento</mat-label>
                  <input matInput type="number" formControlName="discount" />
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="flex-box container">
          <button
            mat-raised-button
            type="button"
            color="primary"
            [disabled]="mode !== 'create'"
            (click)="userFormGroup.reset()"
          >
            Resetear
          </button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            *ngIf="mode === 'create'"
          >
            Crear
          </button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            *ngIf="mode !== 'create'"
          >
            Editar
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
