<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">

    <section class="mat-typography title-group">
    <span class="title-group"><a mat-button routerLink="/productos/listar"
                                 routerLinkActive="mat-accent"><h2>Productos</h2></a></span>


      <span class="spacer "></span>

      <ul class="title-group">
        <li class="title-group">
          <form class="form-inline">
            <div class="search-bar">
              <input type="text"
                     placeholder=" Buscar productos por nombre"
                     aria-label="Number"
                     class="ng-pristine ng-invalid ng-invalid-required ng-touched"
                     [formControl]="myControl"
                     [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async; let i = index" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
              <button mat-button size="sm" color="accent" type="submit" (click)="searchProduct([myControl.value])">
                Buscar
              </button>

              <a mat-button routerLink="/productos/crear" routerLinkActive="mat-accent" color="accent">Crear</a>

            </div>
          </form>

        </li>

      </ul>
      <mat-divider></mat-divider>
    </section>

    <!--ACCORDION FOR FOUNDED PRODUCTS-->
    <div class="some-margin">
      <mat-accordion multi="true" *ngIf="hasFound">
        <mat-expansion-panel class="founded-panel">

          <mat-expansion-panel-header [collapsedHeight]="'140px'" [expandedHeight]="'140px'">

            <div class="draw-main">

              <mat-grid-list cols="9" rowHeight="100px">
                <mat-grid-tile colspan="2" rowspan="1">
                  <div class="draw-inner">
                    <img src="{{searchedProduct.image1Path}}" alt="..." class="img-thumbnail">
                  </div>
                </mat-grid-tile>

                <mat-grid-tile colspan="6" rowspan="1">
                  <div class="draw-inner-inner">
                    {{searchedProduct.name}}
                  </div>
                </mat-grid-tile>

                <mat-grid-tile colspan="1" rowspan="1">
                  <div *ngIf="searchedProduct.mainProduct">
                    <mat-icon color="accent">star_rate</mat-icon>
                  </div>

                </mat-grid-tile>

              </mat-grid-list>
            </div>


          </mat-expansion-panel-header>

          <div class="center-mat">
            <mat-divider></mat-divider>
          </div>


          <div class="draw-main">
            <div class="row just-draw">


              <div class="col-md-6 just-draw">
                <div class="just-draw img-main">

                  <div *ngIf="imageSearchedSelected; else elseVideoBlock">
                    <img src="{{mainSearchedImg}}" alt="..." class="img-thumbnail">

                  </div>
                  <ng-template #elseVideoBlock>
                    <div *ngIf="mainSearchedVideo !== 'null'">
                      <iframe width="355" height="395" [src]="getSafeUrl(0)" frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen></iframe>
                    </div>

                  </ng-template>

                </div>
                <div class="just-draw">

                  <div class="thumbnail-img">

                    <a (click)="onChangeMainImageSearchProduct(searchedProduct.image1Path)"><img
                      src="{{searchedProduct.image1Path}}" class="rounded img-thumbnail float-left" alt="..."></a>
                    <a (click)="onChangeMainImageSearchProduct(searchedProduct.image2Path)"><img
                      src="{{searchedProduct.image2Path}}" class="rounded img-thumbnail float-left" alt="..."></a>

                    <div *ngIf="searchedProduct?.videoUrl !== 'null'">
                      <div class="testing-center">
                        <a (click)="onChangeMainImage('video', 0)">
                          <mat-icon>play_circle_outline</mat-icon>
                        </a>
                      </div>
                    </div>

                  </div>

                </div>
              </div>


              <!--          Block for saving GENERALITIES OF THE PRODUCT-->
              <div class="col-md-6 just-draw">


                <div class="price just-draw container">
                  <div class="grid-container-category">
                    <div class="grid-item-category">{{searchedProduct.name}}</div>
                  </div>
                </div>

                <div class="price just-draw container">
                  <div class="grid-container-subitem">
                    <div class="grid-item-subitem">Categoría: {{searchedProduct.category}}</div>
                  </div>
                </div>

                <!--            <div class="price just-draw container">-->
                <!--              <div class="grid-container-subitem">-->
                <!--                <div class="grid-item-subitem">Cantidad por caja máster: {{product.masterBox}}</div>-->
                <!--              </div>-->
                <!--            </div>-->

                <!--            <div class="price just-draw container">-->
                <!--              <div class="grid-container-subitem">-->
                <!--                <div class="grid-item-subitem">Referencia: {{product.reference}}</div>-->
                <!--              </div>-->
                <!--            </div>-->

                <div class="presentations just-draw container">
                  <!--              {{product | json}}-->

                </div>
              </div>
            </div>

            <div class="center-mat">
              <mat-divider></mat-divider>

            </div>


            <div class="">
              <h2>Descripción del producto</h2>
            </div>

            <div class="row just-draw container">


              <div class="grid-container-subitem ">
                {{searchedProduct.description}}
              </div>

            </div>


            <div class="center-mat">
              <mat-divider></mat-divider>
            </div>


            <div class="row just-draw container">
              <div>
                <h2>Referencias, dimensiones, pesos y precios</h2>
              </div>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">#</th>

                  <th scope="col">C. Barras</th>
                  <th scope="col">Referencia</th>
                  <th scope="col">Caja master</th>

                  <th scope="col">Tipo de presentación</th>
                  <th scope="col">Cantidad por <br> presentación</th>
                  <th scope="col">Largo (cm)</th>
                  <th scope="col">Ancho (cm)</th>
                  <th scope="col">Profundidad (cm)</th>
                  <th scope="col">Peso (gr)</th>
                  <th scope="col">Precio ($)</th>
                  <th scope="col">Precio PVP ($)</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let p of searchedProduct.presentations; let i = index">
                  <th scope="row">{{ i + 1}}</th>

                  <td>{{ p.barCode }}</td>
                  <td>{{ p.reference }}</td>
                  <td>{{ p.masterBox }}</td>

                  <td>{{ p.unitsType }}</td>
                  <td>{{ p.unitsQuantity }}</td>
                  <td>{{ p.large }}</td>
                  <td>{{ p.width }}</td>
                  <td>{{ p.depth }}</td>
                  <td>{{ p.weight }}</td>
                  <td>{{ p.price }}</td>
                  <td>{{ p.pvpPrice }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>


          <mat-action-row>
            <a mat-button color="primary" [routerLink]="['/productos/editar', searchedProduct._id]">Editar</a>
            <button mat-button color="warn" (click)="openDialog(searchedProduct._id)">Eliminar</button>
          </mat-action-row>
        </mat-expansion-panel>

      </mat-accordion>

    </div>


    <mat-spinner color="accent" *ngIf="isLoading"></mat-spinner>
    <mat-accordion multi="true" *ngIf="products.length > 0 && !isLoading">

      <mat-expansion-panel *ngFor="let product of products; let i = index">


        <mat-expansion-panel-header [collapsedHeight]="'140px'" [expandedHeight]="'140px'">

          <div class="draw-main">

            <mat-grid-list cols="9" rowHeight="100px">
              <mat-grid-tile colspan="2" rowspan="1">
                <div class="draw-inner">
                  <img src="{{product.image1Path}}" alt="..." class="img-thumbnail">
                </div>
              </mat-grid-tile>

              <mat-grid-tile colspan="6" rowspan="1">
                <div class="draw-inner-inner">
                  {{product.name}}
                </div>
              </mat-grid-tile>

              <mat-grid-tile colspan="1" rowspan="1">
                <div *ngIf="product.mainProduct">
                  <mat-icon color="accent">star_rate</mat-icon>
                </div>
                <div>&nbsp; &nbsp; &nbsp; &nbsp;</div>
                <div *ngIf="product.available">
                  <mat-icon color="accent">motion_photos_on</mat-icon>
                </div>
              </mat-grid-tile>

            </mat-grid-list>
          </div>


        </mat-expansion-panel-header>

        <div class="center-mat">
          <mat-divider></mat-divider>
        </div>


        <div class="draw-main">
          <div class="row just-draw">


            <div class="col-md-6 just-draw">
              <div class="just-draw img-main">

                <div *ngIf="imageSelected[i]; else elseVideoBlock">
                  <img src="{{mainImg[i]}}" alt="..." class="img-thumbnail">

                </div>
                <ng-template #elseVideoBlock>
                  <div *ngIf="mainVideo[i] !== 'null'">
                    <iframe width="355" height="395" [src]="getSafeUrl(i)" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>

                </ng-template>

              </div>
              <div class="just-draw">

                <div class="thumbnail-img">

                  <a (click)="onChangeMainImage(product.image1Path, i)"><img src="{{product.image1Path}}"
                                                                             class="rounded img-thumbnail float-left"
                                                                             alt="..."></a>
                    <a  *ngIf="product.image2Path" (click)="onChangeMainImage(product.image2Path, i)"><img src="{{product.image2Path}}"
                                                                               class="rounded img-thumbnail float-left"
                                                                               alt="..."></a>

                    <a  *ngIf="product.image3Path" (click)="onChangeMainImage(product.image3Path, i)"><img src="{{product.image3Path}}"
                                                                               class="rounded img-thumbnail float-left"
                                                                               alt="..."></a>
                  <a  *ngIf="product.image4Path" (click)="onChangeMainImage(product.image4Path, i)"><img src="{{product.image4Path}}"
                                                                                                         class="rounded img-thumbnail float-left"
                                                                                                         alt="..."></a>
                  <a  *ngIf="product.image5Path" (click)="onChangeMainImage(product.image5Path, i)"><img src="{{product.image5Path}}"
                                                                                                         class="rounded img-thumbnail float-left"
                                                                                                         alt="..."></a>
                  <a  *ngIf="product.image6Path" (click)="onChangeMainImage(product.image6Path, i)"><img src="{{product.image6Path}}"
                                                                                                         class="rounded img-thumbnail float-left"
                                                                                                         alt="..."></a>
                  <div *ngIf="product?.videoUrl !== 'null' && product?.videoUrl !== 'NaN' ">
                    <div class="testing-center">
                      <a (click)="onChangeMainImage('video', i)">
                        <mat-icon>play_circle_outline</mat-icon>
                      </a>
                    </div>
                  </div>

                </div>

              </div>
            </div>


            <!--          Block for saving GENERALITIES OF THE PRODUCT-->
            <div class="col-md-6 just-draw">


              <div class="price just-draw container">
                <div class="grid-container-category">
                  <div class="grid-item-category">{{product.name}}</div>
                </div>
              </div>

              <div class="price just-draw container">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Categoría: {{newProductsCategories[product?.category]?.name}}</div>
                  <!--                <div class="grid-item-subitem">Categoría: {{product.category}}</div>-->

                </div>
              </div>

              <!--            <div class="price just-draw container">-->
              <!--              <div class="grid-container-subitem">-->
              <!--                <div class="grid-item-subitem">Cantidad por caja máster: {{product.masterBox}}</div>-->
              <!--              </div>-->
              <!--            </div>-->

              <!--            <div class="price just-draw container">-->
              <!--              <div class="grid-container-subitem">-->
              <!--                <div class="grid-item-subitem">Referencia: {{product.reference}}</div>-->
              <!--              </div>-->
              <!--            </div>-->

              <div class="presentations just-draw container">
                <!--              {{product | json}}-->

              </div>
            </div>
          </div>

          <div class="center-mat">
            <mat-divider></mat-divider>

          </div>


          <div class="">
            <h2>Descripción del producto</h2>
          </div>

          <div class="row just-draw container">


            <div class="grid-container-subitem ">
              {{product.description}}
            </div>

          </div>


          <div class="center-mat">
            <mat-divider></mat-divider>
          </div>


          <div class="row just-draw container">
            <div>
              <h2>Referencias, dimensiones, pesos y precios</h2>
            </div>
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">#</th>

                <th scope="col">C. Barras</th>
                <th scope="col">Referencia</th>
                <th scope="col">Caja master</th>

                <th scope="col">Tipo de presentación</th>
                <th scope="col">Cantidad por <br> presentación</th>
                <th scope="col">Largo (cm)</th>
                <th scope="col">Ancho (cm)</th>
                <th scope="col">Profundidad (cm)</th>
                <th scope="col">Peso (gr)</th>
                <th scope="col">Precio ($)</th>
                <th scope="col">Precio PVP ($)</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let p of product.presentations; let i = index">
                <th scope="row">{{ i + 1}}</th>

                <td>{{ p.barCode }}</td>
                <td>{{ p.reference }}</td>
                <td>{{ p.masterBox }}</td>

                <td>{{ p.unitsType }}</td>
                <td>{{ p.unitsQuantity }}</td>
                <td>{{ p.large }}</td>
                <td>{{ p.width }}</td>
                <td>{{ p.depth }}</td>
                <td>{{ p.weight }}</td>
                <td>{{ p.price }}</td>
                <td>{{ p.pvpPrice }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>


        <mat-action-row>
          <a mat-button color="primary" [routerLink]="['/productos/editar', product._id]">Editar</a>
          <button mat-button color="warn" (click)="openDialog(product._id)">Eliminar</button>
        </mat-action-row>
      </mat-expansion-panel>

    </mat-accordion>
    <mat-paginator [length]="totalProducts" [pageSize]="productsPerPage" [pageSizeOptions]="pageSizeOptions"
                   (page)="onChangedPage($event)"
                   *ngIf="products.length > 0"></mat-paginator>
    <p *ngIf="products.length <= 0 && !isLoading" class="info-text">No hay productos añadidos!</p>
  </div>
</div>
