import { ProductCreateComponent } from './products/product-create/product-create.component';
import { HeaderComponent } from './header/header-component';
import { ProductListComponent } from './products/product-list/product-list.component'

import {CategoryListComponent} from "./category/category-list/category-list.component";
import {CategoryCreateComponent} from "./category/category-create/category-create.component";

import { HomeDashboardComponent} from "./home-dashboard/home-dashboard.component";
import { UserCreateComponent} from "./users/clients/user-create/user-create.component";
import { UserListComponent} from "./users/clients/user-list/user-list.component";

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app.routing.module";

import {LoginComponent} from "./users/admins/login/login.component";

import {SafePipe} from "./products/product-create/xss.component";
import {DialogConfirmation} from "./shared/dialog.service";




import {AdminCreateComponent} from "./users/admins/admin-create/admin-create.component";
import {AdminListComponent} from "./users/admins/admin-list/admin-list.component";
import {AuthInterceptor} from "./users/admins/auth-interceptor";

import {AngularMaterialModule} from "./angular-material.module";

@NgModule({
  declarations: [
    AppComponent,
    ProductCreateComponent,
    HeaderComponent,
    ProductListComponent,
    HomeDashboardComponent,
    UserCreateComponent,
    UserListComponent,
    SafePipe,
    DialogConfirmation,
    AdminCreateComponent,
    AdminListComponent,
    LoginComponent,
    CategoryListComponent,
    CategoryCreateComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularMaterialModule,

  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
