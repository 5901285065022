<div class="container">
  <mat-card>
    <mat-spinner *ngIf="isLoading"></mat-spinner>

      <form (submit)="onLogin(loginForm)" #loginForm="ngForm" *ngIf="!isLoading">
        <mat-card class="sub-field">

          <h3>Inicio de sesión</h3>
        <div class="container">
          <mat-form-field>
            <input matInput required name="username" ngModel type="text" placeholder="Nombre de usuario">
          </mat-form-field>
        </div>
        <div class="container">
          <mat-form-field>
            <input matInput required name="password" ngModel type="password" placeholder="Contraseña">
          </mat-form-field>
        </div>
        </mat-card>

        <div class="flex-box container">
          <button mat-raised-button color="primary" type="submit">Entrar</button>
        </div>

      </form>

  </mat-card>

</div>
