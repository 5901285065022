import {Category} from "./category.model";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class CategoryService {
  private categories: Category[] = [];
  private categoriesUpdated = new Subject<{ categories: Category[] }>();
  categoriesListNames = [];
  categoriesListListener = new Subject<{ productsList: [] }>()

  CATEGORIES_LIST = environment.apiUrl + '/categories';

  constructor(private httpClient: HttpClient, private router: Router,) {
  }

  getCategoryListListener() {
    return this.categoriesListListener.asObservable();
  }

  getCategories() {
    this.httpClient.get<{ message: string, categories: Category[] }>(this.CATEGORIES_LIST)
      .subscribe((categoriesData) => {
        this.categories = categoriesData.categories;
        this.categoriesUpdated.next({
          categories: [...this.categories]
        });
      });
  }

  getCategoryUpdateListener() {
    return this.categoriesUpdated.asObservable();
  }

  getCategory(id: string) {
    return this.httpClient.get<{
      _id: null, name: null,
      available: null, image1Path: string
    }>(this.CATEGORIES_LIST + '/' + id)
  }

  addCategory(category, image1: File) {
    const categoryData = new FormData();
    categoryData.append('name', category.name);
    categoryData.append('available', category.available)
    categoryData.append("files", image1, 'img1-' + category.name);

    this.httpClient.post<{ message: string, category: Category }>(this.CATEGORIES_LIST, categoryData)
      .subscribe((responseData) => {
        this.router.navigate(['categorias/listar']);
      })
  }

  updateCategory(id: string, category: Category, image1: File | string) {
    let categoryData: Category | FormData;
    if (typeof image1 === 'object') {
      categoryData = new FormData();
      categoryData.append('_id', id);
      categoryData.append('available', category.available.toString());
      categoryData.append('name', category.name);
      categoryData.append("files", image1, 'img1-' + category.name);
    } else if (typeof image1 !== 'object') {
      categoryData = {
        _id: id,
        available: category.available,
        name: category.name,
        image1Path: image1,
      };
    }
    this.httpClient.put(this.CATEGORIES_LIST + '/' + id, categoryData)
      .subscribe(response => {
        this.router.navigate(['categorias/listar']);
      });
  }

  deleteCategory(categoryId: string) {
    return this.httpClient.delete<{ message: string }>(this.CATEGORIES_LIST + '/' + categoryId)
  }
}
