import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {CategoryListComponent} from "./category/category-list/category-list.component";
import {CategoryCreateComponent} from "./category/category-create/category-create.component";


import {ProductListComponent} from "./products/product-list/product-list.component";
import {ProductCreateComponent} from "./products/product-create/product-create.component";
import {HomeDashboardComponent} from "./home-dashboard/home-dashboard.component";
import {UserCreateComponent} from "./users/clients/user-create/user-create.component";
import {UserListComponent} from "./users/clients/user-list/user-list.component";
import {AdminCreateComponent} from "./users/admins/admin-create/admin-create.component";
import {AdminListComponent} from "./users/admins/admin-list/admin-list.component";
import {LoginComponent} from "./users/admins/login/login.component";
import {AuthGuard} from "./users/admins/auth.guard";

const routes: Routes = [

  {path: 'dashboard', component: HomeDashboardComponent, canActivate: [AuthGuard]},

  {path: 'categorias/listar', component: CategoryListComponent, canActivate: [AuthGuard]},
  {path: 'categorias/crear', component: CategoryCreateComponent, canActivate: [AuthGuard]},
  {path: 'categorias/editar/:categoriaId', component: CategoryCreateComponent, canActivate: [AuthGuard]},

  {path: 'productos/listar', component: ProductListComponent, canActivate: [AuthGuard]},
  {path: 'productos/crear', component: ProductCreateComponent, canActivate: [AuthGuard]},
  {path: 'productos/editar/:productId', component: ProductCreateComponent, canActivate: [AuthGuard]},

  {path: 'usuarios/listar', component: UserListComponent, canActivate: [AuthGuard]},
  {path: 'usuarios/crear', component: UserCreateComponent, canActivate: [AuthGuard]},
  {path: 'usuarios/editar/:userId', component: UserCreateComponent, canActivate: [AuthGuard]},

  {path: 'administradores/listar', component: AdminListComponent, canActivate: [AuthGuard]},
  {path: 'administradores/crear', component: AdminCreateComponent, canActivate: [AuthGuard]},
  {path: 'administradores/editar/:userId', component: AdminCreateComponent, canActivate: [AuthGuard]},

  {path: 'login', component: LoginComponent},
  {path: '', component: LoginComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {

}
