import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class CustomValidationService {
  constructor() {}

  integerPositiveValidator(value) {
    return (formGroup: FormGroup) => {
      const valueControl = formGroup.controls[value];
      if (
        parseFloat(valueControl.value) == parseInt(valueControl.value) &&
        !isNaN(valueControl.value) &&
        valueControl.value > 0
      ) {
        valueControl.setErrors(null);
      } else {
        valueControl.setErrors({ invalidNumber: true });
      }
    };
  }

  birthdayValidaton(value) {
    return (formGroup: FormGroup) => {
      const valueControl = formGroup.controls[value];
      if (!valueControl.value) {
        valueControl.setErrors({ invalidNumber: true });
        return;
      }

      const date = new Date(valueControl.value);

      if (
        !isNaN(valueControl.value) &&
        new Date().getFullYear() - date.getFullYear() >= 18
      ) {
        valueControl.setErrors(null);
      } else {
        valueControl.setErrors({ invalidNumber: true });
      }
    };
  }

  phoneValidator(value) {
    return (formGroup: FormGroup) => {
      const valueControl = formGroup.controls[value];
      if (!isNaN(valueControl.value) && valueControl?.value?.length > 3) {
        valueControl.setErrors(null);
      } else {
        valueControl.setErrors({ invalidNumber: true });
      }
    };
  }

  rucValidator(value) {
    return (formGroup: FormGroup) => {
      const valueControl = formGroup.controls[value];
      let regex10 = /^(?=.*?[0-9]).{10,10}$/g;
      let regex13 = /^(?=.*?[0-9]).{13,13}$/g;
      if (
        !isNaN(valueControl.value) &&
        (regex10.test(valueControl.value) || regex13.test(valueControl.value))
      ) {
        valueControl.setErrors(null);
      } else {
        valueControl.setErrors({ invalidNumber: true });
      }
    };
  }

  passwordValidator(password: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      let regex = /^(?=.*?[A-Z])(?=.*?[a-z]).{5,}$/g;

      if (regex.test(passwordControl.value)) {
        passwordControl.setErrors(null);
      } else {
        passwordControl.setErrors({ invalidPass: true });
      }
    };
  }

  passwordMatchValidator(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];
      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }
      if (
        confirmPasswordControl.errors &&
        !confirmPasswordControl.errors.passwordMismatch
      ) {
        return null;
      }
      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }
}
