import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AdminService} from "./admin.service";
import {Router} from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private adminService: AdminService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLogged = this.adminService.getIsLogged()
    if(!isLogged){
      this.router.navigate(['/login']);
    }
    return isLogged;
  }
}
