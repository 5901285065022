import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from "@angular/common/http";
import {Injectable} from "@angular/core";
import construct = Reflect.construct;
import {A} from "@angular/cdk/keycodes";
import {AdminService} from "./admin.service";
import {Observable} from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
  constructor(private adminService: AdminService){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.adminService.getToken();
    const authRequest = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken)
    });
    return next.handle(authRequest);
  }
}
