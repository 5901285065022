<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">

  <section class="mat-typography title-group">
    <span class="title-group"><a mat-button routerLink="/administradores/listar" routerLinkActive="mat-accent"><h2>Administradores</h2></a></span>
    <span class="spacer "></span>
    <ul class="title-group">
      <li>
        <a mat-button routerLink="/administradores/crear" routerLinkActive="mat-accent">Crear</a>
      </li>
    </ul>
    <mat-divider></mat-divider>
  </section>

    <mat-spinner color="accent"  *ngIf="isLoading"></mat-spinner>

    <mat-accordion multi="true" *ngIf="users.length > 0 && !isLoading">

      <mat-expansion-panel *ngFor="let user of users; let i = index">


        <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
          <div class="container">
            <div class="one">
              Username: {{user.username}}
            </div>
            <div class="two">
              Nombres: {{user.names}} {{user.lastNames}}
            </div>

            <div class="three">
              <div *ngIf="user.isActive === 'true'">
                <mat-icon color="accent">motion_photos_on</mat-icon>
              </div>
            </div>

          </div>
        </mat-expansion-panel-header>
        <div class="center-mat">
          <mat-divider></mat-divider>
        </div>

        <div class="draw-main">
          <div class="row just-draw">
            <!--          Block for saving GENERALITIES OF THE USER-->
            <div class="col-md-12 just-draw">

              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Contraseña: &nbsp;&nbsp;&nbsp; {{user.password}}</div>
                </div>
              </div>

              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Cédula: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; {{user.ced}}</div>
                </div>
              </div>

              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Tipo: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{user.category}}</div>
                </div>
              </div>



            </div>
          </div>
        </div>

        <mat-action-row>
          <a mat-button color="primary" [routerLink]="['/administradores/editar', user._id]">Editar</a>
          <button mat-button color="warn" (click)="openDialog(user._id)">Eliminar</button>
        </mat-action-row>
      </mat-expansion-panel>

    </mat-accordion>
    <p *ngIf="users.length <= 0 && !isLoading" class="info-text">No hay administradores añadidos!</p>

</div>
</div>
