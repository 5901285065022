<mat-toolbar color="primary">
  <span><a routerLink="/"><img src="./assets/static-images/toolbarLogo.png"></a></span>
  <span class="spacer"></span>
  <ul *ngIf="adminIsAuthenticated">
    <li>
      <h2>{{username}}</h2>
      <a mat-button (click)="onLogout()"><mat-icon>exit_to_app</mat-icon>Salir</a>
    </li>
  </ul>
</mat-toolbar>
