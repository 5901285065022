import {Component, OnDestroy, OnInit} from "@angular/core";
import {AdminService} from "../users/admins/admin.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header-component.html',
  styleUrls: ['./header-component.css']
})
export class HeaderComponent implements OnInit, OnDestroy{
  adminIsAuthenticated = false;
  username: string;
  private authListenerSubs: Subscription;
  constructor(public adminService: AdminService) { }

  ngOnInit(){

    this.authListenerSubs = this.adminService.getAuthStatusListener().subscribe((isAuthenticaded) => {
      this.adminIsAuthenticated = isAuthenticaded;
      this.username = this.adminService.username;
    })

    this.adminIsAuthenticated = this.adminService.getIsLogged();
    this.username = this.adminService.username;



  }
  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }
  onLogout(){
    this.adminService.logOutAdmin();
  }
}
