import {Component, OnInit} from "@angular/core";
import {Category} from "../category.model";
import { DomSanitizer } from '@angular/platform-browser';
import {AdminService} from "../../users/admins/admin.service";

import {
  AbstractControl,
  FormGroup,
  Validators,
  FormBuilder,
  } from "@angular/forms";
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {CategoryService} from "../category.service";
import {ActivatedRoute, ParamMap} from "@angular/router";
import { mimeType } from "./mime-type.validator";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-product-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class CategoryCreateComponent implements OnInit {
  adminIsAuthenticated = false;
  isAvailable: boolean = true;
  isLoading = false;
  formGroup: FormGroup;
  category: Category;

  mode = 'create';
  private categoryId: string;

  imagePreview1: string;

  get formArray(): AbstractControl | null {
    return this.formGroup.get('formArray');
  }
  constructor(private _formBuilder: FormBuilder, public categoryService: CategoryService,
              public route: ActivatedRoute, private _sanitizer: DomSanitizer,
              public dialog: MatDialog,
              public adminService: AdminService) {}

  ngOnInit() {
    this.adminIsAuthenticated = this.adminService.getIsLogged();
    this.formGroup = this._formBuilder.group({
      available: [this.isAvailable, [Validators.required]],
      nameCtrl: [null, [Validators.required]],
      image1: ['', [Validators.required], [mimeType]],
    });
    this.isLoading = true
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.isLoading = false
      if(paramMap.has('categoriaId')){
        this.mode = 'edit';
        this.categoryId = paramMap.get('categoriaId')
        this.categoryService.getCategory(this.categoryId).subscribe(productData => {
          this.category = {
            _id: productData._id,
            available: productData.available,
            name: productData.name,
            image1Path: productData.image1Path,
          }
          this.isAvailable = productData.available;
          this.imagePreview1 = productData.image1Path;
          this.formGroup.controls.image1.setValue(this.imagePreview1)
        });
      } else {
        this.mode = 'create';
        this.categoryId = null;
      }
    });
  };

  onSaveCategory(form) {
    if (form.value.invalid || form.status === 'INVALID') {
      return;
    }
    this.isLoading = true
    const category: Category = {
      _id: null,
      available: form.value.available,
      name: form.value.nameCtrl,
      image1Path: null
    };
    if(this.mode === 'create'){
      this.categoryService.addCategory(category,
        this.formGroup.controls.image1.value)
    } else {
      this.categoryService.updateCategory(this.categoryId, category,
        this.formGroup.controls.image1.value)
    }
    this.isLoading = false
  };

  onToggle(){
    this.isAvailable = !this.isAvailable;
    this.formGroup.controls.available.setValue(this.isAvailable)
  };

  onImagePicked(event:Event, imageReference){
    const file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader()
    if(imageReference === 'image1'){
      this.formGroup.controls.image1.setValue(file)
      reader.onload = () => {
        this.imagePreview1 = reader.result as string;
      };
    }
    this.formGroup.controls.image1.updateValueAndValidity();
    reader.readAsDataURL(file);
  };
}
