import {Product} from "./product.model";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class ProductsService {
  private products: Product[] = [];
  private productsUpdated = new Subject<{ products: Product[], productCount: number }>();
  productsListNames = [];
  productsListListener = new Subject<{ productsList: [] }>()

  PRODUCTS_LIST_TOSEARCH_URL = environment.apiUrl + '/other-services/get-products-list';
  PRODUCTS_LIST = environment.apiUrl + '/productos';

  constructor(private httpClient: HttpClient, private router: Router,) {
  }

  getProductsListListener() {
    return this.productsListListener.asObservable();
  }

  getListOfProductsNamesToSearch() {
    this.httpClient.get<{ productsList: [] }>(this.PRODUCTS_LIST_TOSEARCH_URL)
      .subscribe((respData) => {
        // console.log('Lista de nombres de productos recuperada')
        this.productsListNames = respData.productsList;
        this.productsListListener.next({
          productsList: respData.productsList
        })
      })
  }

  getProducts(productsPerPage: number, currentPage: number) {
    const queryParams = `?pageSize=${productsPerPage}&page=${currentPage}`;
    this.httpClient.get<{ message: string, products: Product[], maxProducts: number }>(this.PRODUCTS_LIST + queryParams)
      .subscribe((productsData) => {
        // console.log(productsData.message)

        this.products = productsData.products;
        this.productsUpdated.next({
          products: [...this.products],
          productCount: productsData.maxProducts
        });
      });
  }

  getProductUpdateListener() {
    return this.productsUpdated.asObservable();
  }

  getProduct(id: string) {
    return this.httpClient.get<{
      _id: null, name: null,
      available: null, mainProduct: null, category: null, description: null, presentations: [], image1Path: string,
      image2Path: string, image3Path: string, image4Path: string, image5Path: string, image6Path: string,
      videoUrl: string
    }>(this.PRODUCTS_LIST + '/' + id)
  }

  addProduct(product, image1: File, image2, image3, image4, image5, image6) {
    const productData = new FormData();
    productData.append('name', product.name);
    productData.append('description', product.description);
    productData.append('available', product.available)
    productData.append('mainProduct', product.mainProduct)
    productData.append('category', product.category)
    productData.append('presentations', JSON.stringify(product.presentations));
    productData.append("files", image1, 'img1-' + product.name);
    if (image2)
      productData.append("files", image2, 'img2-' + product.name);
    if (image3)
      productData.append("files", image3, 'img3-' + product.name);
    if (image4)
      productData.append("files", image4, 'img4-' + product.name);
    if (image5)
      productData.append("files", image5, 'img5-' + product.name);
    if (image6)
      productData.append("files", image6, 'img6-' + product.name);
    productData.append('videoUrl', product.videoUrl)
    this.httpClient.post<{ message: string, product: Product }>(this.PRODUCTS_LIST, productData)
      .subscribe((responseData) => {
        this.router.navigate(['productos/listar']);
      })
  }

  updateProduct(id: string, product: Product, image1: File | string, image2: File | string, image3: File | string, image4: File | string, image5: File | string, image6: File | string) {
    let productData: Product | FormData;
    productData = new FormData();
    productData.append('_id', id);
    productData.append('available', product.available.toString());
    productData.append('mainProduct', product.mainProduct.toString());
    productData.append('category', product.category);
    productData.append('name', product.name);
    productData.append('description', product.description);
    productData.append('presentations', JSON.stringify(product.presentations));
    if(image1 && typeof image1 === 'object')
      productData.append("files" , image1, 'img1-' + product.name);
    else
      productData.append( "filesPath1", image1);

    if(image2 && typeof image2 === 'object')
      productData.append("files" , image2, 'img2-' + product.name);
    else
      productData.append( "filesPath2", image2);

    if(image3 && typeof image3 === 'object')
      productData.append("files" , image3, 'img3-' + product.name);
    else
      productData.append( "filesPath3", image3);

    if(image4 && typeof image4 === 'object')
      productData.append("files" , image4, 'img4-' + product.name);
    else
      productData.append( "filesPath4", image4);

    if(image5 && typeof image5 === 'object')
      productData.append("files" , image5, 'img5-' + product.name);
    else
      productData.append( "filesPath5", image5);

    if(image6 && typeof image6 === 'object')
      productData.append("files" , image6, 'img6-' + product.name);
    else
      productData.append( "filesPath6", image6);

    productData.append('videoUrl', product.videoUrl)



    // if(typeof image1 === 'object' && typeof image2 === 'object'){
    //   productData = new FormData();
    //   productData.append('_id', id);
    //   productData.append('available', product.available.toString());
    //   productData.append('mainProduct', product.mainProduct.toString());
    //   productData.append('category', product.category);
    //   // productData.append('reference', product.reference);
    //   productData.append('name', product.name);
    //   // productData.append('masterBox', product.masterBox);
    //   productData.append('description', product.description);
    //   productData.append('presentations', JSON.stringify(product.presentations));
    //   productData.append("files", image1, 'img1-' + product.name);
    //   productData.append("files", image2, 'img2-' + product.name);
    //   productData.append('videoUrl', product.videoUrl)
    //
    // } else if (typeof image1 !== 'object' && typeof image2 !== 'object'){
    //   productData = {
    //     _id: id,
    //     available: product.available,
    //     mainProduct: product.mainProduct,
    //     category: product.category,
    //     // reference: product.reference,
    //     name: product.name,
    //     // masterBox: product.masterBox,
    //     description: product.description,
    //     presentations: JSON.stringify(product.presentations),
    //     image1Path: image1,
    //     image2Path: image2,
    //     videoUrl: product.videoUrl
    //   };
    // } else if(typeof image1 === 'object' && typeof image2 !== 'object' ){
    //   productData = new FormData();
    //   productData.append('_id', id);
    //
    //   productData.append('available', product.available.toString());
    //   productData.append('mainProduct', product.mainProduct.toString());
    //   productData.append('category', product.category);
    //
    //   // productData.append('reference', product.reference);
    //   productData.append('name', product.name);
    //   // productData.append('masterBox', product.masterBox);
    //   productData.append('description', product.description);
    //   productData.append('presentations', JSON.stringify(product.presentations));
    //   productData.append("files", image1, 'img1-' + product.name);
    //   productData.append("filesPath2", image2);
    //   productData.append('videoUrl', product.videoUrl);
    //
    // } else if(typeof image1 !== 'object' && typeof image2 === 'object' ){
    //   productData = new FormData();
    //   productData.append('_id', id);
    //
    //   productData.append('available', product.available.toString());
    //   productData.append('mainProduct', product.mainProduct.toString());
    //   productData.append('category', product.category);
    //
    //   // productData.append('reference', product.reference);
    //   productData.append('name', product.name);
    //   // productData.append('masterBox', product.masterBox);
    //   productData.append('description', product.description);
    //   productData.append('presentations', JSON.stringify(product.presentations));
    //   productData.append("files", image2, 'img2-' + product.name);
    //   productData.append("filesPath1", image1);
    //   productData.append('videoUrl', product.videoUrl);
    //
    // }

    this.httpClient.put(this.PRODUCTS_LIST + '/' + id, productData)
      .subscribe(response => {
        this.router.navigate(['productos/listar']);
      });
  }

  deleteProduct(productId: string) {
    return this.httpClient.delete<{ message: string }>(this.PRODUCTS_LIST + '/' + productId)
  }
}
