import {Component, OnDestroy, OnInit} from "@angular/core";
import {Order} from "./order.model";
import {Observable, Subscription} from "rxjs";
import {OrdersService} from "./orders.service";
import {MatDialog} from "@angular/material/dialog";
import {AdminService} from "../users/admins/admin.service";
import {PageEvent} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {User} from "../users/clients/user.model";

@Component({
  selector: 'app-user-list',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.css']
})
export class HomeDashboardComponent implements OnInit, OnDestroy{
  orders: Order[] = [];
  isLoading = false
  adminIsAuthenticated = false
  private orderSub: Subscription;

  totalOrders = 0;
  ordersPerPage = 10;
  pageSizeOptions = [1, 2, 5, 10, 20]
  currentPage = 1;

  hasFound = false;


  ordersList = []

  orderSelectedId: string;

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  constructor(public dialog: MatDialog,
              private adminService: AdminService,
              private orderService: OrdersService) {  }

  ngOnInit() {
    this.adminIsAuthenticated = this.adminService.getIsLogged();

    this.isLoading = true;

    this.orderService.getOrders(this.ordersPerPage,this.currentPage);

    this.orderSub = this.orderService.getOrderUpdateListener()
      .subscribe((orders: { orders: Order[], orderCount: number }) =>{
        this.isLoading = false;
        this.orders = orders.orders;
        this.totalOrders = orders.orderCount;

      })
  }

  ngOnDestroy() {
    this.orderSub.unsubscribe();
  }

  onChangedPage(pageData: PageEvent){
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.ordersPerPage = pageData.pageSize;
    this.orderService.getOrders(this.ordersPerPage,this.currentPage)
  }


}
