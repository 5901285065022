<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">

    <section class="mat-typography title-group">
      <span class="title-group"><a mat-button routerLink="/categorias/listar"
                                   routerLinkActive="mat-accent"><h2>Categorías</h2></a></span>
      <span class="spacer "></span>
      <ul class="title-group">
        <li>
          <a mat-button routerLink="/categorias/crear" routerLinkActive="mat-accent">Crear</a>
        </li>
      </ul>
      <mat-divider></mat-divider>
    </section>

    <mat-spinner color="accent" *ngIf="isLoading"></mat-spinner>
    <mat-accordion multi="true" *ngIf="categories.length > 0 && !isLoading">
      <mat-expansion-panel *ngFor="let category of categories; let i = index">
        <mat-expansion-panel-header [collapsedHeight]="'140px'" [expandedHeight]="'140px'">
          <div class="draw-main">
            <mat-grid-list cols="9" rowHeight="100px">
              <mat-grid-tile colspan="2" rowspan="1">
                <div class="draw-inner">
                  <img src="{{category.image1Path}}" alt="..." class="img-thumbnail">
                </div>
              </mat-grid-tile>

              <mat-grid-tile colspan="6" rowspan="1">
                <div class="draw-inner-inner">
                  {{category.name}}
                </div>
              </mat-grid-tile>

              <mat-grid-tile colspan="1" rowspan="1">
                <div *ngIf="category.available">
                  <mat-icon color="accent">motion_photos_on</mat-icon>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </mat-expansion-panel-header>

        <mat-action-row>
          <a mat-button color="primary" [routerLink]="['/categorias/editar', category._id]">Editar</a>
        </mat-action-row>

      </mat-expansion-panel>

    </mat-accordion>

    <p *ngIf="categories.length <= 0 && !isLoading" class="info-text">No hay categorías añadidas!</p>

  </div>
</div>
