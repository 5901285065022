import { Component, OnInit } from '@angular/core';
import { Product } from '../product.model';
import { Category } from '../../category/category.model';

import { DomSanitizer } from '@angular/platform-browser';
import { DialogConfirmation } from '../../shared/dialog.service';
import { AdminService } from '../../users/admins/admin.service';

import {
  AbstractControl,
  NgForm,
  FormArray,
  FormGroup,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ProductsService } from '../products.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { mimeType } from './mime-type.validator';
import { mimeTypeVideo } from './mime-type-video.validator';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CategoryService } from '../../category/category.service';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class ProductCreateComponent implements OnInit {
  adminIsAuthenticated = false;
  presentationUnitsList: string[] = ['1', '6', '12', '24', '36'];
  presentationWeightList: string[] = ['1', '5', '10', '20', '50'];
  mainProduct: boolean = false;
  isAvailable: boolean = true;
  isLoading = false;

  testing: string[] = ['1', '6', '12', '24', '36'];

  productsCategories: Category[];

  formGroup: FormGroup;
  product: Product;
  x_values = [];
  y_values = [];
  z_values = [];
  w_values = [];
  p_values = [];
  pvp_values = [];
  quantity_values = [];
  masterBoxPresentations = [];
  references = [];
  barCodes = [];
  type_values = [];
  mode = 'create';
  private productId: string;

  imagePreview1: string;
  imagePreview2: string;
  imagePreview3: string;
  imagePreview4: string;
  imagePreview5: string;
  imagePreview6: string;

  videoPreview: string;
  videoPreviewLabel: string;

  safeVideoUrl: any;

  private authListenerSubs: Subscription;
  private categoriesSub: Subscription;

  get formArray(): AbstractControl | null {
    return this.formGroup.get('formArray');
  }

  constructor(
    private _formBuilder: FormBuilder,
    public productService: ProductsService,
    public route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    public categoriesService: CategoryService,
    public dialog: MatDialog,
    public adminService: AdminService
  ) {}

  ngOnInit() {
    this.adminIsAuthenticated = this.adminService.getIsLogged();

    this.formGroup = this._formBuilder.group({
      formArray: this._formBuilder.array([
        this._formBuilder.group({
          available: [this.isAvailable, [Validators.required]],
          mainProduct: [this.mainProduct, [Validators.required]],
          category: [null, [Validators.required]],

          // refCtrl: [null, [Validators.required]],
          nameCtrl: [null, [Validators.required]],
          // masterBox: [null, [Validators.required]],
          descriptionCtrl: [null, [Validators.required]],
        }),
        this._formBuilder.group({
          presentations: this._formBuilder.array([]),
        }),
        this._formBuilder.group({
          image1: ['', [Validators.required], [mimeType]],
          image2: ['', [], []],
          image3: ['', [], []],
          image4: ['', [], []],
          image5: ['', [], []],
          image6: ['', [], []],
          video: [null, [], []],
        }),
      ]),
    });

    this.categoriesService.getCategories();
    this.categoriesSub = this.categoriesService
      .getCategoryUpdateListener()
      .subscribe((categoriesData: { categories: Category[] }) => {
        this.isLoading = false;
        this.productsCategories = categoriesData.categories;
      });

    this.isLoading = true;
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.isLoading = false;

      if (paramMap.has('productId')) {
        this.mode = 'edit';
        this.productId = paramMap.get('productId');

        this.productService
          .getProduct(this.productId)
          .subscribe((productData) => {
            if (productData.videoUrl !== 'null') {
              this.product = {
                _id: productData._id,
                available: productData.available,
                mainProduct: productData.mainProduct,
                category: productData.category,
                // reference: productData.reference,
                name: productData.name,
                // masterBox: productData.masterBox,
                description: productData.description,
                presentations: productData.presentations,
                image1Path: productData.image1Path,
                image2Path: productData.image2Path,
                image3Path: productData.image3Path,
                image4Path: productData.image4Path,
                image5Path: productData.image5Path,
                image6Path: productData.image6Path,

                videoUrl: productData.videoUrl,
              };
            } else {
              this.product = {
                _id: productData._id,
                available: productData.available,
                mainProduct: productData.mainProduct,
                category: productData.category,
                // reference: productData.reference,
                name: productData.name,
                // masterBox: productData.masterBox,
                description: productData.description,
                presentations: productData.presentations,
                image1Path: productData.image1Path,
                image2Path: productData.image2Path,
                image3Path: productData.image3Path,
                image4Path: productData.image4Path,
                image5Path: productData.image5Path,
                image6Path: productData.image6Path,
                videoUrl: null,
              };
            }

            this.imagePreview1 = this.product.image1Path;
            this.imagePreview2 = this.product.image2Path;
            this.imagePreview3 = this.product.image3Path;
            this.imagePreview4 = this.product.image4Path;
            this.imagePreview5 = this.product.image5Path;
            this.imagePreview6 = this.product.image6Path;

            this.isAvailable = productData.available;
            this.mainProduct = productData.mainProduct;

            // this.formGroup.get('formArray')['controls'][0]['controls']['refCtrl'].setValue(productData.reference);
            this.formGroup
              .get('formArray')
              ['controls'][0]['controls']['nameCtrl'].setValue(
                productData.name
              );
            // this.formGroup.get('formArray')['controls'][0]['controls']['masterBox'].setValue(productData.masterBox);
            this.formGroup
              .get('formArray')
              ['controls'][0]['controls']['descriptionCtrl'].setValue(
                productData.description
              );

            this.formGroup
              .get('formArray')
              ['controls'][0]['controls']['available'].setValue(
                productData.available
              );

            this.formGroup
              .get('formArray')
              ['controls'][0]['controls']['mainProduct'].setValue(
                productData.mainProduct
              );

            this.formGroup
              .get('formArray')
              ['controls'][0]['controls']['category'].setValue(
                productData.category
              );

            this.formGroup
              .get('formArray')
              ['controls'][2]['controls']['image1'].setValue(
                this.product.image1Path
              );
            this.formGroup
              .get('formArray')
              ['controls'][2]['controls']['image2'].setValue(
                this.product.image2Path
              );
            this.formGroup
              .get('formArray')
              ['controls'][2]['controls']['image3'].setValue(
                this.product.image3Path
              );
            this.formGroup
              .get('formArray')
              ['controls'][2]['controls']['image4'].setValue(
                this.product.image4Path
              );
            this.formGroup
              .get('formArray')
              ['controls'][2]['controls']['image5'].setValue(
                this.product.image5Path
              );
            this.formGroup
              .get('formArray')
              ['controls'][2]['controls']['image6'].setValue(
                this.product.image6Path
              );

            this.formGroup
              .get('formArray')
              ['controls'][2]['controls']['video'].setValue(
                this.product.videoUrl
              );

            for (var i = 0; i < this.product.presentations.length; i++) {
              this.onAddProductPresentation('1');

              this.presentationsForm
                .at(i)
                ['controls']['x'].setValue(
                  this.product.presentations[i]['large']
                );
              this.x_values.push(this.product.presentations[i]['large']);

              this.presentationsForm
                .at(i)
                ['controls']['y'].setValue(
                  this.product.presentations[i]['width']
                );
              this.y_values.push(this.product.presentations[i]['width']);

              this.presentationsForm
                .at(i)
                ['controls']['z'].setValue(
                  this.product.presentations[i]['depth']
                );
              this.z_values.push(this.product.presentations[i]['depth']);

              this.presentationsForm
                .at(i)
                ['controls']['w'].setValue(
                  this.product.presentations[i]['weight']
                );
              this.w_values.push(this.product.presentations[i]['weight']);

              this.presentationsForm
                .at(i)
                ['controls']['price'].setValue(
                  this.product.presentations[i]['price']
                );
              this.p_values.push(this.product.presentations[i]['price']);

              this.presentationsForm
                .at(i)
                ['controls']['pvpPrice'].setValue(
                  this.product.presentations[i]['pvpPrice']
                );
              this.pvp_values.push(this.product.presentations[i]['pvpPrice']);

              this.presentationsForm
                .at(i)
                ['controls']['barCode'].setValue(
                  this.product.presentations[i]['barCode']
                );
              this.barCodes.push(this.product.presentations[i]['barCode']);

              this.presentationsForm
                .at(i)
                ['controls']['reference'].setValue(
                  this.product.presentations[i]['reference']
                );
              this.references.push(this.product.presentations[i]['reference']);

              this.presentationsForm
                .at(i)
                ['controls']['masterBox'].setValue(
                  this.product.presentations[i]['masterBox']
                );
              this.masterBoxPresentations.push(
                this.product.presentations[i]['masterBox']
              );

              this.presentationsForm
                .at(i)
                ['controls']['presentationQuantity'].setValue(
                  this.product.presentations[i]['unitsQuantity']
                );
              this.quantity_values.push(
                this.product.presentations[i]['unitsQuantity']
              );

              this.presentationsForm
                .at(i)
                ['controls']['presentationType'].setValue(
                  this.product.presentations[i]['unitsType']
                );

              this.type_values.push(this.product.presentations[i]['unitsType']);
            }
          });
      } else {
        this.mode = 'create';
        this.productId = null;
        this.onAddProductPresentation('1');
      }
    });
  }

  get presentationsForm() {
    return this.formGroup
      .get('formArray')
      ['controls'][1].get('presentations') as FormArray;
  }

  get imagesForm() {
    return this.formGroup
      .get('formArray')
      ['controls'][2].get('images') as FormArray;
  }

  onAddProductPresentation(s) {
    const presentation = this._formBuilder.group({
      presentationType: ['', [Validators.required]],
      presentationQuantity: [''],
      x: ['', [Validators.required, Validators.min(1), Validators.max(999)]],
      y: ['', [Validators.required, Validators.min(1), Validators.max(999)]],
      z: ['', [Validators.required, Validators.min(1), Validators.max(999)]],
      w: [
        '',
        [Validators.required, Validators.min(0.01), Validators.max(999999)],
      ],
      price: [
        '',
        [Validators.required, Validators.min(0.01), Validators.max(9999)],
      ],
      pvpPrice: [
        '',
        [Validators.required, Validators.min(0.01), Validators.max(9999)],
      ],
      barCode: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      masterBox: [
        '',
        [Validators.required, Validators.min(1), Validators.max(9999)],
      ],
    });

    this.presentationsForm.push(presentation);

    // I need that 2 to diferentiate between starting and clicking in the button
    if (s === '2' && this.mode === 'edit') {
      this.x_values.push('');
      this.y_values.push('');
      this.z_values.push('');
      this.w_values.push('');
      this.p_values.push('');
      this.pvp_values.push('');
      this.barCodes.push('');
      this.references.push('');
      this.masterBoxPresentations.push('');
    }
  }

  deletePresentation() {
    this.presentationsForm.removeAt(this.presentationsForm.length - 1);
  }

  onSaveProduct(form, stepper, formDirective) {
    if (form.value.invalid) {
      return;
    }
    this.isLoading = true;

    const product: Product = {
      _id: null,
      available: form.value.formArray[0].available,
      mainProduct: form.value.formArray[0].mainProduct,
      category: form.value.formArray[0].category,
      // reference: form.value.formArray[0].refCtrl,
      name: form.value.formArray[0].nameCtrl,
      // masterBox: form.value.formArray[0].masterBox,
      description: form.value.formArray[0].descriptionCtrl,
      presentations: form.value.formArray[1].presentations,
      image1Path: null,
      image2Path: null,
      image3Path: null,
      image4Path: null,
      image5Path: null,
      image6Path: null,
      videoUrl: form.value.formArray[2].video,
    };
    if (this.mode === 'create') {
      this.productService.addProduct(
        product,
        this.formGroup.get('formArray')['controls'][2].get('image1').value,
        this.formGroup.get('formArray')['controls'][2].get('image2').value,
        this.formGroup.get('formArray')['controls'][2].get('image3').value,
        this.formGroup.get('formArray')['controls'][2].get('image4').value,
        this.formGroup.get('formArray')['controls'][2].get('image5').value,
        this.formGroup.get('formArray')['controls'][2].get('image6').value
      );
    } else {
      this.productService.updateProduct(
        this.productId,
        product,
        this.formGroup.get('formArray')['controls'][2].get('image1').value,
        this.formGroup.get('formArray')['controls'][2].get('image2').value,
        this.formGroup.get('formArray')['controls'][2].get('image3').value,
        this.formGroup.get('formArray')['controls'][2].get('image4').value,
        this.formGroup.get('formArray')['controls'][2].get('image5').value,
        this.formGroup.get('formArray')['controls'][2].get('image6').value
      );
    }
    this.isLoading = false;

    return;

    // Reset the stepper and the form after submitting for entering more products
    this.resetFormsAndStepper(stepper, formDirective);
  }

  resetFormsAndStepper(stepper, formDirective) {
    this.formGroup.reset();
    stepper.reset();
    formDirective.resetForm();
  }

  onButtonPresentation(clickedValue, i) {
    if (this.mode === 'create') {
    }
    this.presentationsForm
      .at(i)
      ['controls']['presentationQuantity'].setValue('');
  }

  resetProduct(i) {
    this.presentationsForm.at(i).reset();
  }

  isValid() {
    return this.presentationsForm.length > 1;
  }

  onImagePicked(event: Event, imageReference) {
    const file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();

    if (imageReference === 'image1') {
      // this.formGroup.get('formArray')['controls'][2].patchValue({image1: file});
      this.formGroup
        .get('formArray')
        ['controls'][2].get('image1')
        .setValue(file);

      reader.onload = () => {
        this.imagePreview1 = reader.result as string;
      };
    } else if (imageReference === 'image2') {
      this.formGroup
        .get('formArray')
        ['controls'][2].patchValue({ image2: file });
      reader.onload = () => {
        this.imagePreview2 = reader.result as string;
      };
    } else if (imageReference === 'image3') {
      this.formGroup
        .get('formArray')
        ['controls'][2].patchValue({ image3: file });
      reader.onload = () => {
        this.imagePreview3 = reader.result as string;
      };
    } else if (imageReference === 'image4') {
      this.formGroup
        .get('formArray')
        ['controls'][2].patchValue({ image4: file });
      reader.onload = () => {
        this.imagePreview4 = reader.result as string;
      };
    } else if (imageReference === 'image5') {
      this.formGroup
        .get('formArray')
        ['controls'][2].patchValue({ image5: file });
      reader.onload = () => {
        this.imagePreview5 = reader.result as string;
      };
    } else if (imageReference === 'image6') {
      this.formGroup
        .get('formArray')
        ['controls'][2].patchValue({ image6: file });
      reader.onload = () => {
        this.imagePreview6 = reader.result as string;
      };
    } else if (imageReference === 'video') {
    }

    this.formGroup
      .get('formArray')
      ['controls'][2].get(imageReference)
      .updateValueAndValidity();
    reader.readAsDataURL(file);
  }

  getSafeUrl() {
    this.videoPreview = this.formGroup
      .get('formArray')
      ['controls'][2].get('video').value;
    if (this.videoPreview !== null && this.videoPreview !== 'null') {
      const newUrl = this.videoPreview.replace('watch?v=', 'embed/');
      return this._sanitizer.bypassSecurityTrustResourceUrl(newUrl);
    }
  }
}
