import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AdminService} from "./users/admins/admin.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  adminIsAuthenticated = false;
  private authListenerSubs: Subscription;
  constructor(private authService: AdminService) {}

  ngOnInit(){
    this.authService.autoAuthUser();

    this.adminIsAuthenticated = this.authService.getIsLogged();


    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe((isAuthenticated) => {
      this.adminIsAuthenticated = isAuthenticated;
    })
  }

  ngOnDestroy() {
  }
}
