<div class="outter-center" *ngIf="adminIsAuthenticated">
  <div class="inner-center">

  <section class="mat-typography title-group">
    <span class="title-group"><a mat-button routerLink="/usuarios/listar" routerLinkActive="mat-accent"><h2>Clientes</h2></a></span>
    <span class="spacer "></span>
    <ul class="title-group">
      <li>
        <form class="form-inline">
          <div class="search-bar">
            <input type="text"
                   placeholder=" Buscar cliente por nombres"
                   aria-label="Number"
                   class="ng-pristine ng-invalid ng-invalid-required ng-touched"
                   [formControl]="myControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async; let i = index" [value]="option" >
                {{option}}
              </mat-option>
            </mat-autocomplete>
            <button mat-button size="sm" color="accent" type="submit" (click)="searchClient([myControl.value])">Buscar</button>
          </div>
        </form>
<!--        <a mat-button routerLink="/usuarios/crear" routerLinkActive="mat-accent">Crear</a>-->
      </li>
    </ul>
    <mat-divider></mat-divider>


<!--    ACCORDEON FOR A SEARCHED CLIENT. JUST FOR SEARCHED-->
    <div class="some-margin">
      <mat-accordion multi="true" *ngIf="hasFound" >
        <mat-expansion-panel class="founded-panel">
          <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
            <div class="container">
              <div class="one">
                RUC: {{searchedClient.ruc}}
              </div>
              <div class="two">
                Nombres: {{searchedClient.names}} {{searchedClient.lastNames}}
              </div>
              <div class="three">
                Descuento: {{searchedClient.discount}}
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="center-mat">
            <mat-divider></mat-divider>
          </div>
          <div class="draw-main">
            <div class="row just-draw">
              <!--          Block for saving GENERALITIES OF THE USER-->
              <div class="col-md-12 just-draw">
                <div class="price just-draw container row">
                  <div class="grid-container-subitem">
                    <div class="grid-item-subitem">Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.email}}</div>
                  </div>
                </div>


                <div class="center-mat">
                  <mat-divider></mat-divider>
                </div>

                <div class="price just-draw container row">
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Teléfono 1: &nbsp;&nbsp; {{searchedClient.phone1}}</div>
                  </div>
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Teléfono 2: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.phone2}}</div>
                  </div>
                </div>
                <div class="price just-draw container row">
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Provincia: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.province}}</div>
                  </div>
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Cantón: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.city}}</div>
                  </div>
                </div>
                <div class="price just-draw container row">
                  <div class="grid-container-subitem">
                    <div class="grid-item-subitem">Dirección: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.address}}</div>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <div class="price just-draw container row">
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Sexo: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.sex}}</div>
                  </div>
                  <div class="grid-container-subitem col-md-6">
                    <div class="grid-item-subitem">Nacimiento: &nbsp;&nbsp;&nbsp;&nbsp; {{searchedClient.birthday.split('T')[0]}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <mat-action-row>
            <a mat-button color="primary" [routerLink]="['/usuarios/editar', searchedClient._id]">Editar</a>
            <button mat-button color="warn" (click)="openDialog(searchedClient._id)">Eliminar</button>
          </mat-action-row>
        </mat-expansion-panel>

      </mat-accordion>

    </div>


<!--    <mat-divider></mat-divider>-->


  </section>
    <mat-spinner color="accent"  *ngIf="isLoading"></mat-spinner>
    <mat-accordion multi="true" *ngIf="users.length > 0 && !isLoading">
      <mat-expansion-panel *ngFor="let user of users; let i = index">
        <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
          <div class="container">
            <div class="one">
              RUC: {{user.ruc}}
            </div>
            <div class="two">
              Nombres: {{user.names}} {{user.lastNames}}
            </div>
            <div class="three">
              Descuento: {{user.discount}}
            </div>
          </div>
        </mat-expansion-panel-header>
        <div class="center-mat">
          <mat-divider></mat-divider>
        </div>
        <div class="draw-main">
          <div class="row just-draw">
            <!--          Block for saving GENERALITIES OF THE USER-->
            <div class="col-md-12 just-draw">
              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; {{user.email}}</div>
                </div>
              </div>


              <div class="center-mat">
                <mat-divider></mat-divider>
              </div>

              <div class="price just-draw container row">
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Teléfono 1: &nbsp;&nbsp; {{user.phone1}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Teléfono 2: &nbsp;&nbsp;&nbsp;&nbsp; {{user.phone2}}</div>
                </div>
              </div>
              <div class="price just-draw container row">
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Provincia: &nbsp;&nbsp;&nbsp;&nbsp; {{user.province}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Cantón: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{user.city}}</div>
                </div>
              </div>
              <div class="price just-draw container row">
                <div class="grid-container-subitem">
                  <div class="grid-item-subitem">Dirección: &nbsp;&nbsp;&nbsp;&nbsp; {{user.address}}</div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="price just-draw container row">
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Sexo: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{user.sex}}</div>
                </div>
                <div class="grid-container-subitem col-md-6">
                  <div class="grid-item-subitem">Nacimiento: &nbsp;&nbsp;&nbsp;&nbsp; {{user.birthday.split('T')[0]}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <mat-action-row>
          <a mat-button color="primary" [routerLink]="['/usuarios/editar', user._id]">Editar</a>
          <button mat-button color="warn" (click)="openDialog(user._id)">Eliminar</button>
        </mat-action-row>
      </mat-expansion-panel>

    </mat-accordion>

    <mat-paginator [length]="totalProducts" [pageSize]="productsPerPage" [pageSizeOptions]="pageSizeOptions" (page)="onChangedPage($event)"
                   *ngIf="users.length > 0"></mat-paginator>
    <p *ngIf="users.length <= 0 && !isLoading" class="info-text">No hay usuarios añadidos!</p>

</div>
</div>
