import {Component, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";

import {PageEvent} from "@angular/material/paginator";
import {ProductsService} from "../products.service";

import {DomSanitizer} from "@angular/platform-browser";
import {DialogConfirmation} from "../../shared/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {Product} from "../product.model";
import {AdminService} from "../../users/admins/admin.service";
import {FormControl} from "@angular/forms";
import {User} from "../../users/clients/user.model";
import {map, startWith} from "rxjs/operators";
import {CategoryService} from "../../category/category.service";
import {Category} from "../../category/category.model";

const dataTransform = (data) => {
  let newData = {};
  for (let i = 0; i < data.length; i++){
    newData[data[i]._id] = data[i]
  }
  return newData
}

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})



export class ProductListComponent implements OnInit, OnDestroy{
  products: Product[] = [];
  mainImg: string[] = [];
  mainVideo: string[] = [];
  imageSelected: boolean[] = []
  isLoading = false

  mainSearchedImg: string;
  mainSearchedVideo: string;
  imageSearchedSelected: boolean = true;

  totalProducts = 0;
  productsPerPage = 10;
  pageSizeOptions = [1, 2, 5, 10, 20]
  currentPage = 1;

  hasFound = false;
  searchBarListenerSub: Subscription;
  productsList = []
  productsListNames = []
  productSelectedId: string;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  searchedProduct: Product;

  private categoriesSub: Subscription;

  productsCategories: Category[];
  newProductsCategories: {};


  private productsSub: Subscription;
  adminIsAuthenticated = false;
  private authStatusSubs: Subscription;

  constructor(private authService: AdminService,
              public productsService: ProductsService,
              public categoriesService: CategoryService,
              private  _sanitizer: DomSanitizer,
              public dialog: MatDialog, ) {  }


  ngOnInit() {

    this.adminIsAuthenticated = this.authService.getIsLogged();

    this.authStatusSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.adminIsAuthenticated = isAuthenticated
      })

    this.isLoading = true;

    this.productsService.getProducts(this.productsPerPage, this.currentPage);

    this.productsSub = this.productsService.getProductUpdateListener()
      .subscribe((productsData: {products: Product[], productCount: number}) => {
        this.isLoading = false;

        this.products = productsData.products;
        this.totalProducts = productsData.productCount;

        this.loadImages();

    });


    this.productsService.getListOfProductsNamesToSearch();
    this.searchBarListenerSub = this.productsService.getProductsListListener()
      .subscribe((respData) => {
        this.productsList = respData.productsList

        for(let i = 0; i < this.productsList.length; i++){
          this.productsListNames.push(this.productsList[i]['product'])
        }
      })

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.categoriesService.getCategories()
    this.categoriesSub = this.categoriesService.getCategoryUpdateListener()
      .subscribe((categoriesData: {categories: Category[]}) => {
        this.isLoading = false;
        this.productsCategories = categoriesData.categories;
        this.newProductsCategories = dataTransform(this.productsCategories)
      });




  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.productsListNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  searchProduct(product){

    if(this.productsListNames.includes(product[0])){
      const index = this.productsListNames.indexOf(product[0])
      this.productSelectedId = this.productsList[index]['_id'];
      this.productsService.getProduct(this.productSelectedId).subscribe(productData => {

        this.searchedProduct = {
          _id: productData._id,
          name: productData.name,
          available: productData.available,
          mainProduct: productData.mainProduct,
          category: productData.category,
          description: productData.description,
          presentations: productData.presentations,
          image1Path: productData.image1Path,
          image2Path: productData.image2Path,
          image3Path: productData.image3Path,
          image4Path: productData.image4Path,
          image5Path: productData.image5Path,
          image6Path: productData.image6Path,
          videoUrl: productData.videoUrl
        }

        this.mainSearchedImg = this.searchedProduct.image1Path;

        this.hasFound = true;
        this.myControl.setValue('');

      })
    } else {
      this.hasFound = false;
    }
  }

  loadSearchedImages(){

  }

  loadImages(){

    this.mainImg = [];
    this.mainVideo = [];
    this.imageSelected = [];

    for(let i = 0; i < this.products.length; i++){
      this.mainImg.push(this.products[i].image1Path);
    }

    for(let i = 0; i < this.products.length; i++){
      this.mainVideo.push(this.products[i]?.videoUrl);
    }

    for(let i = 0; i < this.products.length; i++){
      this.imageSelected.push(true);
    }
  }

  ngOnDestroy() {
    this.productsSub.unsubscribe();
    this.authStatusSubs.unsubscribe();
    this.searchBarListenerSub.unsubscribe();
  }

  onDelete(productId){
    this.isLoading = true;
    this.productsService.deleteProduct(productId).subscribe(() => {
      this.productsService.getProducts(this.productsPerPage, this.currentPage);
    });
  }
  whatIs(testing){
    // console.log(testing);
  }

  onChangeMainImageSearchProduct(img){
    if(img === 'video'){
      this.imageSearchedSelected = false;
      return
    }
    this.mainSearchedImg = img
    this.imageSearchedSelected = true;
  }

  onChangeMainImage(img, i){
    if(img === 'video'){
      this.imageSelected[i] = false;
      return
    }
    this.mainImg[i] = img
    this.imageSelected[i] = true;
  }

  getSafeUrl(i){
    if(this.mainVideo[i] !== null){
      const newUrl = this.mainVideo[i].replace('watch?v=', 'embed/')
      this.imageSelected[i] = false;
      return this._sanitizer.bypassSecurityTrustResourceUrl(newUrl);
    }
  }

  openDialog(productId) {
    const dialogRef = this.dialog.open(DialogConfirmation);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true){
        this.onDelete(productId)
      }
    });
  }

  onChangedPage(pageData: PageEvent){

    this.isLoading = true;

    this.currentPage = pageData.pageIndex + 1;

    this.productsPerPage = pageData.pageSize;
    this.productsService.getProducts(this.productsPerPage,this.currentPage)

  }



}
