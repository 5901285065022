import { Component } from "@angular/core";
import {NgForm} from "@angular/forms";
import {AdminService} from "../admin.service";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  isLoggin = true;
  isLoading = false;

  constructor(public adminService: AdminService) { }

  onLogin(form: NgForm){
    if(form.invalid){
      return
    }
    this.adminService.loginAdmin(form.value.username, form.value.password);
  }
}
